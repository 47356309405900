import { CgSpinnerTwoAlt } from "react-icons/cg"; 
import { Box, Button, Modal } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { getContractors, toggleContractor } from "../../../Components/Redux/Slice/DeliveryContractors/deliveryContractor";
import { resetPage, resetSearch, resetSort } from "../../../Components/Redux/Slice/ResetPagination/resetPagination";

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '1px solid #ddd',
    boxShadow: 24,
    px: 1,
    py:2,
    borderRadius : '10px'
};

const WarningProviderModal = ({open,close,warnType,data}) => {
    let id = data?.id
    const {t,i18n} = useTranslation()
    const deliveryContractor = useSelector(state=>state?.deliveryContractor)
    const dispatch = useDispatch()

    const page = 1,
        size = 10
    const toggleProviderHandler = async (status)=>{
        
        await dispatch(toggleContractor({id,status})).then ( (e)=>{
            if(e?.payload?.message === 'SUCCESS'){
                close()
                dispatch(getContractors({page,size}))
                dispatch(resetSearch());
                dispatch(resetSort({sort:true}))
                dispatch(resetPage())
            }
        } )
    }
    return ( 
        <>
            <Modal
                open={open}
                onClose={close}
                aria-labelledby="modal-warning"
                aria-describedby="modal-warning-delete"
            >
                <Box 
                    sx={style} 
                    dir={localStorage?.getItem('lang') === 'ar' ? 'rtl' : 'ltr'} 
                >
                    <div className="warnning-modal-wrapper">
                        <div className="flex justify-end items-center">
                            <div>
                                <Button color="error" className="text-2xl -mt-2" onClick={close}><IoCloseCircleOutline /></Button>
                            </div>
                        </div>

                        <div className={`capitalize text-center w-full font-semibold ${(!data?.isActive && warnType !== 'delete') ? 'text-mainGreen' : 'text-mainRed'} text-lg mt-4`}>
                            <h2>{warnType === 'deactivate' ? t('deliveryContractors.warningModal.deactivate_title') : warnType === 'reactivate' ? t('deliveryContractors.warningModal.reactivate_title') : warnType === 'delete' ? t('deliveryContractors.warningModal.delete_title') : null }</h2>
                        </div>

                        <div className={`flex gap-x-7 items-center mt-8 w-full justify-end [&>button]:capitalize  ${localStorage.getItem('lang') === 'ar' ? 'pl-4 flex-row-reverse' : 'pr-4'} `}>
                            <Button className="bg-gray-400 text-white" onClick={close}>{t('deliveryContractors.warningModal.cancel_btn')}</Button>
                            {warnType === 'deactivate' ?
                                <Button onClick={()=>toggleProviderHandler('deactivate')} disabled={deliveryContractor?.loading} className="bg-mainRed text-mainRed bg-opacity-30 disabled:bg-opacity-40 disabled:pointer-events-auto disabled:cursor-not-allowed min-w-[100px]" >{deliveryContractor?.loading ? <span className="animate-spin text-xl"><CgSpinnerTwoAlt /></span> : t('deliveryContractors.deactivate_provider')}</Button>
                                :
                                warnType === 'reactivate' ?
                                <Button onClick={()=>toggleProviderHandler('reactivate')} disabled={deliveryContractor?.loading} className="bg-mainGreen text-mainGreen bg-opacity-30 disabled:bg-opacity-40 disabled:pointer-events-auto disabled:cursor-not-allowed min-w-[100px]" >{deliveryContractor?.loading ? <span className="animate-spin text-xl"><CgSpinnerTwoAlt /></span> : t('deliveryContractors.reactivate_provider')}</Button>
                                :
                                warnType === 'delete' ?
                                <Button disabled={deliveryContractor?.loading} className="bg-mainRed text-mainRed bg-opacity-30 disabled:bg-opacity-40 disabled:pointer-events-auto disabled:cursor-not-allowed" >{deliveryContractor?.loading ? <span className="animate-spin"><CgSpinnerTwoAlt /></span> : t('deliveryContractors.delete_provider')}</Button>
                                : 
                                null
                            }
                            
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default WarningProviderModal;