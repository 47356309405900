import { Box, Button, FormControl, InputAdornment, ListItemText, MenuItem, Modal, Select, TextField } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { IoCloseCircleOutline } from "react-icons/io5";
import { useDispatch } from "react-redux"
import { editPackage, getAdminPackages } from "../../../Components/Redux/Slice/Packages/packages";
import { useEffect, useState } from "react";
import {resetPage, resetSearch, resetSort} from '../../../Components/Redux/Slice/ResetPagination/resetPagination'

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 850,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 3,
    p: 2,
    borderRadius : 5,
};

const EditPackageModal = ({open,close,t,data}) => {
    const id = data?.id
    const [discountType, setDiscountType] = useState()
    const form = useForm({
        defaultValues : {
            packageName : data?.packageName,
            packageNameAr : data?.packageNameAr,
            discountValue : data?.discountValue,
            discountType : data?.discountType,
            packageType : "delivery_fees_discount",
            packageTypeAr : "خصم_على_التوصيل",
        }
    })
    const {register,control,handleSubmit,formState,setValue,getValues,watch,reset} = form
    const {errors} = formState
    const dispatch = useDispatch()

    const handleClose = ()=>{
        close()
        reset()
    }

    useEffect(() => {
        if(id && open){
            setDiscountType(data?.discountType)
            
            const defaultValues = {
                packageName : data?.packageName,
                packageNameAr : data?.packageNameAr,
                discountValue : data?.discountValue,
                discountType : data?.discountType,
                packageType : "delivery_fees_discount",
                packageTypeAr : "خصم_على_التوصيل",
            }

            reset(defaultValues)
        }
    }, [open, id, reset])
    

    const submitHandler = (values)=>{
        const page = 1,
            size = 10

        const data = {
            ...values,
            description : "",
            descriptionAr : "",
            packageType : "delivery_fees_discount",
            packageTypeAr : "خصم_على_التوصيل",
        }
        
        dispatch(editPackage({data,id})).then( (e)=>{
            if(e?.payload?.message === 'SUCCESS'){
                dispatch(getAdminPackages({page,size}))
                dispatch(resetSearch());
                dispatch(resetSort({sort:true}))
                dispatch(resetPage())
                handleClose()
            }
        } )

        
    }

    return ( 
        <article className="add-package-modal-wrapper">
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="add-offer-modal-title"
                aria-describedby="add-offer-modal-description"
            >
                <Box
                    sx={style} 
                    className='w-[90%] sm:w-[70%] lg:w-[50%] max-w-[990px]'
                    dir={localStorage?.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                >

                    <div className="flex justify-between items-center">
                        <h2 className="text-[#333333] text-lg font-bold mb-3 capitalize">{t('dp.editNewPackage')}</h2>

                        <div>
                            <Button color="error" className="text-2xl -mt-2" onClick={handleClose}><IoCloseCircleOutline /></Button>
                        </div>
                    </div>
                    
                    <form onSubmit={handleSubmit(submitHandler)} noValidate className="menu-modal-form-wrapper mt-5 ">
                        <div className="input-wrapper  [&>div]:mb-3 [&>div>p]:text-xs [&>div>p]:text-mainRed [&>div>label]:capitalize [&>div>label]:text-[#333333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-1 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-2 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                            <div>
                                <label>{t('dp.packNameEn')}</label>
                                <input type="text" {...register('packageName',{
                                    required : {
                                        value : true,
                                        message : t('common.required')
                                    },
                                    pattern:{
                                        value: /^[a-zA-Z+\s]+$/,
                                        message : localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف الانجليزية والمسافات فقط' : 'Only English letters and spaces allowed'
                                    },
                                })} />
                                {errors?.packageName && <p>{errors?.packageName?.message}</p>}
                            </div>

                            <div>
                                <label>{t('dp.packNameAr')}</label>
                                <input type="text" {...register('packageNameAr',{
                                    required : {
                                        value : true,
                                        message : t('common.required')
                                    },
                                    pattern: {
                                        value: /^[\u0621-\u064A\s]+$/,
                                        message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف العربية والمسافات ' : 'Only Arabic letters and spaces are allowed'
                                    },
                                })} />
                                {errors?.packageNameAr && <p>{errors?.packageNameAr?.message}</p>}
                            </div>

                            <div className={`[&>div]:bg-[#f3f6f9] [&>div]:rounded-md`}>
                                <label>{t('dp.discountType')}</label>
                                <FormControl sx={{width: '100%' }}>
                                    {/* {feesType ? null : <InputLabel shrink={false} id="demo-simple-select-label" className="text-[#8a8a8a] text-sm">{t('deliveryContractors.form.flatFeesTimeFreqancyPlaceholder')}</InputLabel>} */}
                                    <Controller
                                        control={control}
                                        name="discountType"
                                        value={discountType}
                                        rules={{required:t('common.required')}}
                                        render={({field,fieldState: { error }})=>(
                                            <>
                                                <Select {...field}
                                                    // label={t('deliveryContractors.form.flatFeesTimeFreqancyPlaceholder')}
                                                    displayEmpty
                                                    disabled
                                                    // inputRef={{...register('payoutFrequency')}}
                                                    labelId="discountType-label"
                                                    id="discountType"
                                                    // value={feesType}
                                                    onChange={(e)=>{
                                                        field.onChange(e)
                                                        setDiscountType(e?.target?.value)
                                                    }}
                                                    // renderValue={(selected) => selected.join(', ')}
                                                    sx={{'& .MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0,},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9',},'& .css-zx5rfr' : {backgroundColor : '#f3f6f9'}}}
                                                >
                                                    <MenuItem value={'fixed'}>
                                                        <ListItemText className='capitalize' primary={'fixed'} />
                                                    </MenuItem>

                                                    <MenuItem value={'percentage'}>
                                                        <ListItemText className='capitalize' primary={'percentage'} />
                                                    </MenuItem>
                                                </Select>                                                    
                                            </>
                                        )}
                                        
                                    />
                                    
                                </FormControl>

                                {errors?.discountType && <p className="capitalize text-sm text-danger mt-1">{errors?.discountType?.message}</p>}
                            </div>


                            {discountType === 'fixed' ?
                            
                            <div className="relative">
                                <label>{t('dp.discount')}</label>
                                <TextField
                                    disabled
                                    id="price"
                                    type="number" {...register('discountValue',{
                                        required : {
                                            value : true,
                                            message : t('common.required')
                                        },
                                        pattern : {
                                            value: /^[0-9]+(\.\d+)?$/,
                                            message : t('common.onlyNumbers')
                                        },
                                        // max : {
                                        //     value : 100,
                                        //     message : t('common.max_value',{value : '100'})
                                        // }
                                    })}
                                    slotProps={{
                                        input: {
                                            endAdornment: <InputAdornment position="start">{t('common.sar')}</InputAdornment>,
                                        },
                                        
                                    }}
                                    placeholder={t('common.amount')}
                                    className="bg-[#f3f6f9] w-full outline-none rounded-md"
                                    sx={{'fieldset' : {outline : 'none',border : 'none'}}}
                                />
                                {errors?.discountValue && <p>{errors?.discountValue?.message}</p>}
                            </div>
                            :

                            <div className="relative">
                                <label>{t('dp.discount')}</label>
                                <TextField
                                    disabled
                                    id="price"
                                    type="text" {...register('discountValue',{
                                        required : {
                                            value : true,
                                            message : t('common.required')
                                        },
                                        pattern : {
                                            value: /^[0-9]*$/,
                                            message : t('common.onlyNumbers')
                                        },
                                        max : {
                                            value : 100,
                                            message : t('common.max_value',{value : '100'})
                                        }
                                    })}
                                    slotProps={{
                                        input: {
                                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        },
                                        
                                    }}
                                    placeholder={t('common.percentValue')}
                                    className="bg-[#f3f6f9] w-full outline-none rounded-md"
                                    sx={{'fieldset' : {outline : 'none',border : 'none'}}}
                                />
                                {errors?.discountValue && <p>{errors?.discountValue?.message}</p>}
                            </div>
                            }
                        </div>

                        <div>
                            <div className={`add-new-package mt-4 text-right flex items-center gap-x-3 justify-start ${localStorage.getItem('lang') === 'ar' ? 'flex-row' : 'flex-row-reverse'} [&>button]:min-w-[140px] [&>button]:capitalize`}>
                                <Button type="submit"  className="disabled:opacity-50 disabled:cursor-not-allowed bg-mainColor text-white">{t('common.edit_btn')}</Button>
                                <Button onClick={handleClose} className="bg-transparent text-mainRed border border-solid border-mainRed hover:bg-mainRed hover:text-white">{t('common.cancel_btn')}</Button>
                            </div>
                        </div>
                    </form>
                </Box>
            </Modal>
        </article>
     );
}
 
export default EditPackageModal;