import { AiOutlineCheckCircle } from "react-icons/ai"; 
import { MdNotInterested } from "react-icons/md"; 
import { BiEdit } from "react-icons/bi"; 
import { AiOutlineEye } from "react-icons/ai"; 
import DataTable from "react-data-table-component";
import { useCallback, useEffect, useState } from "react";
import { dateOnlyFormate } from "../../../Components/Common/dateFormate";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormControl, IconButton, InputAdornment, ListItemButton, Menu, MenuItem, OutlinedInput } from "@mui/material";
import { CiSearch } from "react-icons/ci";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import ColumnSelection from "../../../Components/Common/columnSelection";
import { useRemoteSort } from "../../../Components/Common/sortHook";
import NoDataEmoji from "../../../Components/Common/nodataEmoje";
import { TableStyles } from "../../../Components/Common/tableStyle";
import {IoMdAddCircle} from 'react-icons/io'
import { getContractors } from "../../../Components/Redux/Slice/DeliveryContractors/deliveryContractor";
import AddProviderModal from "./addProviderModal";
import EditProviderModal from "./editProviderModal";
import WarningProviderModal from "./warningProviderModal";
import {resetPage} from '../../../Components/Redux/Slice/ResetPagination/resetPagination.js'
import { BsDot } from "react-icons/bs";

function ActionCell({ data, t}) {
    let id = data?.id

    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch()
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    // edit modal state

    const [type,settype] = useState(null)
    const [openEditModal,setopenEditModal] = useState(false)
    const [warnType,setwarnType] = useState(null)

    const handleOpenEditModal = ()=>setopenEditModal(true)
    const handleCloseEditModal = ()=>setopenEditModal(false)

    //warning modal

    const [openWarningModal,setopenWarningModal] = useState(false)

    const handleOpenwarningModal = ()=>setopenWarningModal(true)
    const handleClosewarningModal = ()=>setopenWarningModal(false)


    return (
        <div className='action-wrapper relative'>
            <ListItemButton className='rounded-md' onClick={handleClick}><span><BiDotsHorizontalRounded className='text-xl' /></span></ListItemButton>
            <Menu
                id="datatable-action-menu"
                aria-labelledby="datatable-action-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                PaperProps={{ style: { boxShadow: 'none',padding:0 } }}
                className='shadow-md p-0'
            >
                <div className='[&>li]:mb-2 [&>li>svg]:mr-2 [&>li>svg]:text-xl rounded-md overflow-hidden capitalize'>
                    <MenuItem onClick={()=>{
                        handleOpenEditModal()
                        settype('view')
                    }} className='text-mainYellow hover:bg-mainYellow hover:bg-opacity-25 transition-all duration-300'><AiOutlineEye /> {t('deliveryContractors.view_provider')}</MenuItem>
                    
                    <MenuItem onClick={()=>{
                        handleOpenEditModal()
                        settype('edit')
                    }} className='text-mainColor hover:bg-mainColor hover:bg-opacity-25 '><BiEdit /> {t('deliveryContractors.edit_provider')}</MenuItem>
                    
                    {data?.isActive ? 
                        <MenuItem onClick={()=>{
                            handleOpenwarningModal()
                            setwarnType('deactivate')
                        }} className='text-mainRed hover:bg-mainRed hover:bg-opacity-25 '><MdNotInterested /> {t('deliveryContractors.deactivate_provider')}</MenuItem>
                        :
                        <MenuItem onClick={()=>{
                            handleOpenwarningModal()
                            setwarnType('reactivate')
                        }} className='text-mainGreen hover:bg-mainGreen hover:bg-opacity-25 '><AiOutlineCheckCircle /> {t('deliveryContractors.reactivate_provider')}</MenuItem>
                    }
                    {/* <MenuItem onClick={handleOpenwarningModal} className='text-mainRed bg-mainRed bg-opacity-25 '><MdDeleteSweep /> {t('deliveryContractors.delete_provider')}</MenuItem> */}
                    
                </div>
            </Menu>

            <EditProviderModal open={openEditModal} close={handleCloseEditModal} t={t} data={data} type={type} />
            <WarningProviderModal open={openWarningModal} close={handleClosewarningModal} warnType={warnType} data={data} />
        </div>
    );
}

const AllContractors = ({t}) => {
    const dispatch = useDispatch()
    const deliveryContractor = useSelector(state=>state?.deliveryContractor)
    const [page,setpage] = useState(1)
    const [size,setsize] = useState(10)
    const [searchValue, setsearchValue] = useState('')

    const {currentPage} = useSelector((state) => state?.resetPagination);
    const {toggle,search,sort} = useSelector((state) => state?.resetPagination);
    
    useEffect(() => {
        setpage(currentPage)
    }, [toggle])

    
    useEffect(() => {
        setsearchValue('')        
    }, [search])

    useEffect(() => {
        dispatch(getContractors({page,size}))
    }, [dispatch])

    const handlePageChange = page => {
        setpage(page);
    };

    const handleRowChange = rows => {
        setsize(rows);
    };

    const columns = [
        {
            id: 'id',
            name: 'ID',
            cell: row => row?.id,
            maxWidth : "100px",
            sortable: true,
        },
        {
            id: 'company_name',
            name: t('deliveryContractors.columnsName.companyName'),
            cell: row => row?.companyName,
            sortable: true,
        },
        {
            id: 'company_name_ar',
            name: t('deliveryContractors.columnsName.companyNameAr'),
            selector: row => row?.companyNameAr,
            sortable: true,
        },
        {
            id: 'number_of_agents',
            name: t('deliveryContractors.columnsName.agentNumber'),
            selector: row => row?.numberOfAgents ? row?.numberOfAgents : 0,
            sortable: true,
        },
        {
            id: 'bank_name',
            name: t('deliveryContractors.columnsName.bankName'),
            selector: row => row?.bankName,
            sortable: true,
        },
        // {
        //     id: 'ibanNumber',
        //     name: t('deliveryContractors.columnsName.ibanNumber'),
        //     cell: row => row?.ibanNumber,
        //     sortable: false,
        // },
        {
            id: 'payout_method',
            name: t('deliveryContractors.columnsName.payoutMethod'),
            cell: row => <span className="capitalize">{row?.payoutMethod?.replace(/_/g,' ')?.toLowerCase()}</span>,
            sortable: false,
        },
        // {
        //     id: 'payout_frequency',
        //     name: t('deliveryContractors.columnsName.payoutFrequency'),
        //     selector: row => row?.payoutFrequency?.replace(/_/g,' '),
        //     sortable: true,
        // },
        // {
        //     id: 'payout_amount_type',
        //     name: t('deliveryContractors.columnsName.payoutAmountType'),
        //     selector: row => row?.payoutAmountType?.replace(/_/g,' '),
        //     sortable: true,
        // },
        // {
        //     id: 'payout_flat_fees_value',
        //     name: t('deliveryContractors.columnsName.payoutFlatFeesValue'),
        //     selector: row => row?.payoutFlatFeesValue,
        //     sortable: true,
        // },
        // {
        //     id: 'payout_percentage_value',
        //     name: t('deliveryContractors.columnsName.payoutPercentageValue'),
        //     selector: row=>row?.payoutPercentageValue,
        //     sortable: true,
        // },
        {
            id: 'createdAt',
            name: t('deliveryContractors.columnsName.createdAt'),
            selector: row=>dateOnlyFormate(row?.createdAt),
            sortable: false,
        },
        {
            id: 'status',
            name: t('deliveryContractors.columnsName.status'),
            cell: row=> <span className={`font-semibold px-2 py-1 capitalize rounded-md text-center bg-opacity-25 min-w-[120px] ${row?.isActive ? 'bg-mainGreen text-mainGreen' : 'bg-mainRed text-mainRed'}`}>{row?.isActive ? t('common.active') : t('common.deactivated')}</span>,
            sortable: false,
        },
        {
            id: 'actions',
            name: `${localStorage.getItem('lang') === 'ar' ? 'الاوامر' : 'Actions'}`,
            allowOverflow: true,
            button : true,
            cell: row=>(
                <>
                    {<ActionCell data={row} t={t} />}
                </>
            )
        },
    ];
    
    const data = deliveryContractor?.deliveryContractor?.data

    // console.log(data);
    
    const handleSearch = ()=>{
        dispatch(getContractors({searchValue})).then( ()=> dispatch(resetPage()))
    }

    const handleReset = ()=>{
        dispatch(getContractors({page,size})).then( ()=> dispatch(resetPage()))
        setsearchValue('')
    }

    useEffect(() => {
    //   console.log(searchValue);
    }, [searchValue])


    const {handleRemoteSort, icon} = useRemoteSort(getContractors,dispatch,page,size,searchValue)

    // functions to handle hide / display table columns

    const [selectedColumns, setSelectedColumns] = useState(['id', 'company_name', 'company_name_ar', 'number_of_agents', 'payout_method', 'status', 'actions']);
    
    const [open, setopen] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    const handleOpen = ()=>{
        setopen(true)
    }
    const handleClose = ()=>{
        setopen(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    }, []);

    useEffect(() => {
        setSelectedColumns(['id', 'company_name', 'company_name_ar', 'number_of_agents', 'payout_method', 'status', 'actions']);
    }, []);
    
    useEffect(() => {
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.id)));
    }, [selectedColumns]);


    // add 3pl provider modal


    const [openProviderModal, setopenProviderModal] = useState(false)

    const handleOpenProvider = ()=>{
        setopenProviderModal(true)
    }
    const handleCloseProvider = ()=>{
        setopenProviderModal(false)
    }

    return ( 
        <>
            <section className={`relative couriers-control-wrapper mt-5 w-full ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>

                <div className={`couriers-search-wrapper flex justify-between items-center ${localStorage.getItem('lang') === 'ar' ? 'flex-row' : 'flex-row-reverse'}`}>
                    <div className="flex gap-x-3 items-center">
                        <Button onClick={handleOpenProvider} className={`capitalize text-white bg-mainGreen rounded-md py-2 flex justify-between gap-x-2 ${localStorage.getItem('lang')} ? 'pl-2' : 'pr-2'}`}> <IoMdAddCircle className={`w-6 h-6 rounded-[50%] ${localStorage.getItem('lang')} ? 'ml-2' : 'mr-2' `} /> <span className='text-sm font-playfair'>{t('deliveryContractors.add_btn')}</span></Button>
                        <ColumnSelection columns={columns} handleOpen={handleOpen} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={open} close={handleClose} />
                    </div>
                    
                    <div>
                        <FormControl 
                            className="search-form [&>div>input]:py-[.6rem]"
                            sx={{width: '35ch',backgroundColor:'white',borderRadius:'6px','& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input':{padding : '6px'}, '& fieldset': { borderColor: 'primary.main',border:'none' } }} variant="outlined">
                            <OutlinedInput
                                type="search"
                                id="outlined-adornment-search"
                                value={searchValue}
                                placeholder={localStorage.getItem('lang') === 'ar' ? 'ابحث عن الشركات...' : 'Search Contractors...'}
                                onChange={(e)=>{
                                    setsearchValue(e.target.value)
                                    dispatch(resetPage())
                                    if (!e.target.value) {
                                        handleReset();
                                        dispatch(resetPage())
                                    }}}
                                startAdornment={
                                <InputAdornment position="start">
                                    
                                    <IconButton
                                        aria-label="search"
                                        onClick={handleSearch}
                                        edge="start"
                                    >
                                        <CiSearch />
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                </div>
            </section>

            <section className="datatable-wrapper mt-5 bg-white relative">
                {data?.length ===0 ? 
                    <div className='p-8'><NoDataEmoji /></div>
                :

                <>
                    <DataTable
                        direction={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                        columns={visibleColumns}
                        data={data}
                        pagination
                        paginationPerPage = {size}
                        paginationRowsPerPageOptions = {[10,50,100]}
                        paginationServer
                        paginationTotalRows={deliveryContractor?.deliveryContractor?.meta?.total}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowChange}
                        customStyles={TableStyles}
                        highlightOnHover
                        // onRowClicked={(data)=>{
                        //     navigate('details',{state:{id:data?.driverId,type:'status'}})
                        //     dispatch(clearCourierState())
                        // }}
                        sortServer
                        onSort={handleRemoteSort}
                        sortIcon={sort ? <BsDot className="text-[1px] opacity-0" /> : icon}
                        paginationDefaultPage={page}
                        keyField="id"
                        paginationResetDefaultPage = {true}
                        paginationComponentOptions={
                            {
                                rowsPerPageText : t('common.paginationRowText'),
                                rangeSeparatorText : t('common.of')
                            }
                        }
                    />
                </>
                }

                {deliveryContractor?.loading ?
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                        <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                    </div> : null}
            </section>

            <AddProviderModal t={t} open={openProviderModal} close={handleCloseProvider} />
        </>
    );
}

export default AllContractors;