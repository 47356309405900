import { useEffect, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import previewIcon from '../../../../Assets/imgs/previewIcon.svg'
import { Button, FormControlLabel, FormGroup, Icon, InputAdornment, ListItemButton, MenuItem, Switch, TextField, ThemeProvider, Tooltip} from "@mui/material";
import { BiEditAlt, BiReset } from "react-icons/bi";
import { IoMdAdd, IoMdHelpCircle } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addMenuItem, getCategories } from "../../../../Components/Redux/Slice/AccountManager/Menu/menu";
import Overlay from "../../../../Components/Common/overlay";
import BreadCrumb from "../../../../Components/BreadCrumb/breadCrumb";
import { addFile } from "../../../../Components/Redux/Slice/Upload/file";
import { toast } from "react-toastify";
import { IoTrashOutline } from "react-icons/io5";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Astrisks from '../../../../Components/Common/astrisk'
import { useTranslation } from "react-i18next";
import { alpha, styled } from '@mui/material/styles';
import Options from "./optionsComponent";
import defImage from '../../../../Assets/imgs/Banner.svg'
import PriorityModal from "./priorityModal";
import { v4 as uuidv4 } from 'uuid';
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Compressor from "compressorjs";

const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#442B7E',
      '&:hover': {
        backgroundColor: alpha('#442B7E', theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#442B7E',
    },
  }));
  

const AddNewDish = () => {
    const {t,i18n} = useTranslation()
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate()
    const location = useLocation()
    const form = useForm()
    const {register,control,handleSubmit,formState,setValue,getValues,reset,watch,trigger} = form
    const {errors, isDirty,isValid} = formState
    const [preview, setPreview] = useState(location?.state?.data?.image);
    const [page,setpage] = useState('')
    const dispatch = useDispatch()
    const [textareaCounter, settextareaCounter] = useState(0);
    const [textareaArCounter, settextareaArCounter] = useState(0);

    const {categories} = useSelector(state=>state)
    const {menuItem} = useSelector(state=>state)

    // console.log(location?.state?.data);
    
    // console.log(categories);
    const [img,setimg] = useState(null)
    const [lowImage,setlowImage] = useState(null)
    const [highImage,sethighImage] = useState(null)

    const maxSize = 1024*1024*5
    const compressionLimit = 55
    const acceptedImageTypes = ['image/jpeg', 'image/png'];

    const [loadCompress,setloadCompress] = useState(false)

    const handleImageChange = (e)=> {
        const file = e.target.files[0];

            if(file.size > maxSize){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'أقصي مساحه للملف 5 ميجا بايت' : 'File size exceeds 5MB' ,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else if(!acceptedImageTypes.includes(file.type)){
                MySwal.fire({
                    title: 'Error!',
                    text: 'Images must be in type of jpg,jpeg,png only',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else {
                if((file.size / 1024) < compressionLimit){
                    setimg(file)
                } else {

                    //set low quality image
                    setloadCompress(true)
                    new Compressor(file, {
                        quality: 0.5,
                        maxWidth : 600,
                        maxHeight : 600,


                        success: (compressedResult) => {
                            setloadCompress(false)
                            setimg(compressedResult)                            
                            setlowImage(compressedResult)                            
                        },
                    });

                    // set high quality image
                    setloadCompress(true)
                    new Compressor(file, {
                        quality: 0.7,
                        maxWidth : 1500,
                        maxHeight : 1500,


                        success: (highCompressedResult) => {
                            setloadCompress(false)
                            sethighImage(highCompressedResult)                            
                        },
                    });
                }
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = ()=>{
                setPreview(reader.result);
            }
        }
    }

    const handleImageRest = ()=>{
        setimg(null)
        setPreview(null)
    }

    // console.log(getValues());

    // adds on

    // const [options, setoptions] = useState([]);

    // const addFieldsHandler = () => {
    //     setoptions(options => [...options, {}]);
    // }

    // const [extras, setextras] = useState([]);

    // const addExtrasHandler = () => {
    //     setextras(extras => [...extras, {}]);
    // }

    const { fields: optionGroup, append: appendOptionGroup, remove: removeOptionGroup, move : moveOptionGroup } = useFieldArray({
        control,
        name: 'optionGroups',
    });


    const { fields: extras, append: appendExtra, remove: removeExtra } = useFieldArray({
        control,
        name: 'extras',
    });

    const addOptionGroupHandler = () => {
        appendOptionGroup({ 
            id: uuidv4(),
            options: [{ name: '', nameAr: '', price: '', calories: '' }],
        });
    };

    const removeOptionGroupHandler = (index) => {
        removeOptionGroup(index);
    };

    // const addOptionHandler = () => {
    //     appendOption();
    // };

    // const removeOptionHandler = (index) => {
    //     removeOption(index);
    // };

    const addExtraHandler = () => {
        appendExtra();
    };

    const removeExtraHandler = (index) => {
        removeExtra(index);
    };

    useEffect(() => {

        // console.log(options);

    }, [menuItem?.loading])
    
    watch('optionGroups')
    watch('options')
    watch('extras')
    watch()

    useEffect(() => {
        dispatch(getCategories())

    }, [dispatch, location, page, setValue,getValues])


    // console.log(categories?.loading);

    const submitHandler = async (values)=>{
        // uncomment all to make it work
        // const optionJsonString = values.options.length > 0 ? JSON.stringify(values.options) : [];
        // const extraJsonString = values.extras.length > 0 ? JSON.stringify(values.extras) : []

        const image = new FormData()
        image.append('file',img)
        
        const lowQualityImage = new FormData()
        lowQualityImage.append('file',lowImage)

        const highQualityImage = new FormData()
        highQualityImage.append('file',highImage)

        function isEmpty(obj) {
            return Object.values(obj).every(value => value === "");
        }

        let nonEmptyOptions = values?.options?.filter(obj => !isEmpty(obj));
        let nonEmptyExtras = values?.extras?.filter(obj => !isEmpty(obj));
        let nonEmptyOptionGroup = values?.optionGroups?.filter(obj => !isEmpty(obj));

        const invalidOptionGroupIndex = values?.optionGroups?.findIndex(group => (group.options.length < group.minimumSelections) && !group.allowSingleOptionMultiselection);

        if (invalidOptionGroupIndex !== -1) {
            // Trigger error
            toast.error(`${t('common.invalidLength',{index:invalidOptionGroupIndex + 1})}`);
        } else {
            const updatedOptionGroups = nonEmptyOptionGroup?.map((group, index) => ({
                ...group,
                order: index + 1,
            }));

            if(img){
                let ImageResponse = null,
                    lowImageResponse = null,
                    highImageResponse = null;
                try {
                    ImageResponse = await dispatch(addFile(image));
                    lowImageResponse = await dispatch(addFile(lowQualityImage));
                    highImageResponse = await dispatch(addFile(highQualityImage));
                    
                    if (ImageResponse.payload?.data) {
                        const imageUrl = ImageResponse.payload.data.url;
                        const formData = {
                            ...values, // include all other form data
                            options: nonEmptyOptions || [] , // convert options array to JSON string
                            extras: nonEmptyExtras || [] , // convert extras array to JSON string
                            optionGroups: updatedOptionGroups || [] , // convert extras array to JSON string
                            image : imageUrl,
                            lowQualityImage : lowImageResponse?.payload.data.url,
                            mediumQualityImage : highImageResponse?.payload?.data?.url
                        };
        
                        dispatch(addMenuItem(formData)).then( (e)=>e?.payload?.message === 'SUCCESS' ? navigate('/dashboard/menu') : null );
                    }
                } catch (error) {
                    // console.log(error);
                }
            } else{
                // toast.error('Please Select Image')
                const formData = {
                    ...values, // include all other form data
                    options: nonEmptyOptions || [] , // convert options array to JSON string
                    extras: nonEmptyExtras || [] , // convert extras array to JSON string
                    optionGroups: updatedOptionGroups || [] , // convert extras array to JSON string
                    image : null
                };
                dispatch(addMenuItem(formData)).then( (e)=>e?.payload?.message === 'SUCCESS' ? navigate('/dashboard/partner/menu') : null );
            }
        }
        

    }
    
    useEffect(() => {
        // console.log(textareaCounter);
    }, [textareaCounter,textareaArCounter])

    const {file} = useSelector(state=>state)
    // console.log(file);

    const paths = [
        {path:'/dashboard/partner/menu',name:'Menu',nameAr:"القائمة"},
        {path:'',name:'add new dish',nameAr:"اضف طبق جديد"},
    ]

    // console.log(optionGroup);
    const [disabledInput,setdisabledInput] = useState()

    const toggleDisabledInputs = (index,checked)=>{
        // const disabled = disabledInput[index]
        setdisabledInput( (prev)=>({
            ...prev,
            [index]:checked
        }) )

        // if(checked){
        //     setValue(`optionGroups[${index}].minimumSelections`,1)
        // }
    }
    // console.log(getValues());
    // console.log(disabledInput);
    // console.log(errors);

    // edit priority Modal

    const [openPriority, setopenPriority] = useState(false)

    const handleOpenPriority = ()=>{
        setopenPriority(true)
    }

    const handleClosePriority = ()=>{
        setopenPriority(false)
    }

    const watchedOptionGroup = watch('optionGroups');

    return ( 
        <article className="add-new-dish-wrapper mt-5 mb-10">
            <div className="new-dish-wrapper">
                <section className="new-dish-title-wrapper">
                    <h1 className="text-xl font-semibold text-[#464E5F] font-playfair capitalize">{t('menu.add_new_dish.title')}</h1>
                    <div><BreadCrumb paths={paths} seperator='/' /></div>
                </section>

                <section className="new-dish-grid-wrapper mt-5">
                    <form noValidate onSubmit={handleSubmit(submitHandler)} className="flex flex-wrap justify-center lg:justify-start items-start gap-5 lg:gap-3">

                        <section className="menu-item-image-preview-wrapper basis-full md:basis-[45%] lg:basis-[20%] h-[200px] rounded-xl overflow-hidden">

                            <div className="preview-wrapper shadow-md w-full h-full relative p-2 bg-[#f7f5fd] ">
                                <input type="file" id="image" accept="image/*" onChange={handleImageChange} className="absolute top-0 bottom-0 left-0 right-0 cursor-pointer opacity-1 w-full h-full z-40 opacity-0" />
                                <span onClick={handleImageRest} className="block absolute top-0 right-0 text-2xl bg-[#fff] bg-opacity-30 p-1 text-mainRed cursor-pointer z-[99]"><BiReset /></span>

                                <div className={`absolute top-0 bottom-0 left-0 right-0 cursor-pointer w-full h-full bg-mainYellow ${preview ? 'z-10' : 'z-30'}`}>
                                    <div className="text-center text-white absolute top-0 bottom-0 left-0 right-0 cursor-pointer w-full h-[70%] m-auto flex flex-wrap justify-center items-center [&>*]:basis-full">
                                        <img src={previewIcon} alt="preview" className={`w-[70px] h-[70px]`} />
                                        <p className="capitalize lg:text-sm xl:text-base text-[#fff]">{t('menu.add_new_dish.form.image_label')}</p>
                                        {/* {errors?._?.message ? <p className="capitalize lg:text-sm xl:text-base text-danger">{errors?._?.message}</p> : <p className="capitalize lg:text-sm xl:text-base text-[#fff]">Upload item image</p>} */}
                                    </div>
                                </div>
                                <img src={preview ? preview : defImage} alt="Preview" className="w-full h-full absolute top-0 bottom-0 left-0 right-0 z-20" />
                                
                                {loadCompress ? <div className="absolute top-0 bottom-0 left-0 right-0 z-50 bg-[rgba(0,0,0,.4)] text-white flex items-center justify-center">
                                    <p className="w-fit animate-spin text-3xl"><AiOutlineLoading3Quarters /></p>
                                </div> : null}
                            </div>
                            
                        </section>

                        <section className="upload-item-info-wrapper basis-full lg:basis-[40%] bg-white p-6 rounded-xl">
                            <div className="upload-form-title mb-5">
                                <h2 className="text-lg text-[#333333] font-semibold capitalize ">{t('menu.add_new_dish.title')}</h2>
                            </div>
                            
                            <div className="form-input-wrapper flex flex-wrap justify-between [&>div]:mb-4 [&>div>label]:capitalize [&>div>label]:text-[rgb(51,51,51)] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                                <div className="[&>input]:w-full basis-full">
                                    <label>{t('menu.add_new_dish.form.name_en')} <Astrisks /></label>
                                    <input type="text" id="name"
                                        {...register('name',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            },
                                            pattern:{
                                                value: /^[a-zA-Z0-9+\s!@#$%^&*()-_+~=]+$/,
                                                message : localStorage.getItem('lang') === 'ar' ? 'مسموح فقط بالاحرف الانجليزية والمسافات والعلامات الخاصة ' : 'Only English letters, numbers and special characters are allowed'
                                            },
                                            minLength : {
                                                value : 3,
                                                message : t('common.min_length',{length : '3'})
                                            },
                                            maxLength : {
                                                value : 30,
                                                message : t('common.max_length',{length : '30'})
                                            }
                                        })}

                                        minLength={3}
                                        maxLength={30}
                                    />

                                    {errors?.name?.message ? <p className="capitalize text-sm text-danger">{errors?.name?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.name_en_helper')}</p>}
                                </div>

                                <div className="[&>input]:w-full basis-full">
                                    <label>{t('menu.add_new_dish.form.name_ar')} <Astrisks /></label>
                                    <input type="text" id="nameAr"
                                        {...register('nameAr',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            },
                                            pattern: {
                                                value: /^[\u0621-\u064A\u0660-\u0669\u0030-\u0039\s&0-9!@#$%^&*()-_+=~`{}[\]|\\:;"'<>,.؟،’؛?/]+$/,
                                                message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف العربية والمسافات والارقام و العلامات الخاصة فقط' : 'Only Arabic letters, spaces, numbers and special characters are allowed'
                                            },
                                            minLength : {
                                                value : 3,
                                                message : t('common.min_length',{length : '3'})
                                            },
                                            maxLength : {
                                                value : 30,
                                                message : t('common.max_length',{length : '30'})
                                            }
                                        })}

                                        minLength={3}
                                        maxLength={30}
                                    />

                                    {errors?.nameAr?.message ? <p className="capitalize text-sm text-danger">{errors?.nameAr?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.name_ar_helper')}</p>}
                                </div>

                                <div className="basis-full flex flex-wrap lg:flex-nowrap [&>div>p]:mt-1 [&>div]:basis-full md:[&>div]:basis-[48%] gap-5 [&>div>label]:capitalize [&>div>label]:text-[rgb(51,51,51)] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor">
                                    <div className="[&>div]:bg-[#f3f6f9]">
                                        <label>{t('menu.add_new_dish.form.item_category')} <Astrisks /></label>
                                        {/* <TextField
                                            {...register('categoryId',{
                                                required:{
                                                    value:true,
                                                    message: t('common.required')
                                                }
                                            })}
                                            className="w-full"
                                            id="categoryId"
                                            select
                                            // label="Select"
                                            defaultValue={location?.state?.data?.categoryId}
                                            // helperText="Please select item category"
                                            sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'}}}
                                            >
                                            {categories?.categories?.data?.map( (item)=>(
                                                <MenuItem value={item?.id} key={item?.id}>
                                                    {item?.name}
                                                </MenuItem>
                                            ) )}
                                        </TextField> */}

                                        <Controller
                                            name="categoryId"
                                            control={control}
                                            defaultValue={location?.state?.data?.categoryId}
                                            rules={{
                                                required: {
                                                value: true,
                                                message: t('common.required')
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    id="categoryId"
                                                    className="w-full"
                                                    sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'}}}
                                                >
                                                    {categories?.categories?.data?.map((item) => (
                                                        <MenuItem value={item?.id} key={item?.id}>
                                                        {item?.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                        {errors?.categoryId?.message ? <p className="capitalize text-sm text-danger">{errors?.categoryId?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.item_category_helper')}</p>}
                                    </div>

                                    <div className="[&>div]:bg-[#f3f6f9]">
                                        <label>{t('menu.add_new_dish.form.price')} <Astrisks /></label>

                                        <TextField
                                            type="number"
                                            id="price"
                                            {...register('price',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                                max : {
                                                    value : 2000,
                                                    message : t('common.max_value',{value : '2000'})
                                                },
                                                valueAsNumber : true
                                            })}
                                            // label="With normal TextField"
                                            // sx={{ m: 1, width: '25ch', }}
                                            sx={{'& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'}}}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">{t('common.sar')}</InputAdornment>,
                                                
                                            }}
                                            inputProps={{
                                                min : 0
                                            }}
                                        />

                                        {errors?.price?.message ? <p className="capitalize text-sm text-danger">{errors?.price?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.price_helper')}</p>}
                                    </div>
                                </div>

                                <div className="basis-full [&>input]:w-full">
                                    <label>{t('menu.add_new_dish.form.calories')}</label>
                                    <input type="number" id="calories"
                                        {...register('calories',{
                                            // required:{
                                            //     value : true,
                                            //     message : t('common.required')
                                            // },
                                            min : {
                                                value : 0,
                                                message : t('common.max_value',{value : '0'})
                                            },
                                            max : {
                                                value : 50000,
                                                message : t('common.max_value',{value : '50000'})
                                            },
                                            valueAsNumber : true
                                        })} 
                                        placeholder={localStorage.getItem('lang') === 'ar' ? 'أختياري: 50 كالورى' : 'Optional: 50 KCL'}
                                        min={0}
                                    />
                                    {errors?.calories?.message ? <p className="capitalize text-sm text-danger">{errors?.calories?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.calories_helper')}</p>}
                                </div>

                                <div className="basis-full mt-3">
                                    <label>{t('menu.add_new_dish.form.desc_en')} <Astrisks /></label>
                                    <div className="relative">
                                        <textarea onKeyUp={(e)=>settextareaCounter(e.target.value.length)} name="description" id="description" className="w-full min-h-[100px] h-auto p-3 bg-[#f3f6f9] resize-none rounded-md"
                                            {...register('description',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                                // minLength : {
                                                //     value : 10,
                                                //     message : t('common.min_length',{length : '10'})
                                                // },
                                                // maxLength : {
                                                //     value : 500,
                                                //     message : t('common.max_length',{length : '500'})
                                                // },
                                                // pattern: {
                                                //     value: /^[a-zA-Z0-9&\s]+$/,
                                                //     message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف الانجليزيه والارقام وعلامه & فقط' : 'Only English letters, numbers, and & are allowed'
                                                // },
                                            })}

                                            minLength={10}
                                            maxLength={500}
                                        >
                                        </textarea>
                                        <p className="absolute bottom-2 right-2 text-[12px] text-[#0000005a]">{500 - textareaCounter}</p>
                                    </div>

                                    {errors?.description?.message ? <p className="capitalize text-sm text-danger">{errors?.description?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.desc_en')}</p>}
                                </div>

                                <div className="basis-full mt-3">
                                    <label>{t('menu.add_new_dish.form.desc_ar')} <Astrisks /></label>
                                    <div className="relative">
                                        <textarea onKeyUp={(e)=>settextareaArCounter(e.target.value.length)} name="descriptionAr" id="descriptionAr" className="w-full min-h-[100px] h-auto p-3 bg-[#f3f6f9] resize-none rounded-md"
                                            {...register('descriptionAr',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                                // minLength : {
                                                //     value : 10,
                                                //     message : t('common.min_length',{length : '10'})
                                                // },
                                                // maxLength : {
                                                //     value : 500,
                                                //     message : t('common.max_length',{length : '500'})
                                                // },
                                                pattern: {
                                                    value: /^[\u0621-\u064A\u0660-\u0669\u0030-\u0039\s&0-9!@#$%^&*()-_+=~`{}[\]|\\:;"'<>,.؟’؛?/]+$/,
                                                    message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف العربية والارقام والعلامات الخاصة' : 'Only Arabic letters, numbers, spaces, and special characters are allowed'
                                                },
                                            })}

                                            minLength={10}
                                            maxLength={500}
                                        >
                                        </textarea>
                                        <p className="absolute bottom-2 right-2 text-[12px] text-[#0000005a]">{500 - textareaArCounter}</p>
                                    </div>

                                    {errors?.descriptionAr?.message ? <p className="capitalize text-sm text-danger">{errors?.descriptionAr?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.desc_ar_helper')}</p>}
                                </div>

                            </div>
                        </section>

                        <section className="add-ads-on-wrapper basis-full lg:basis-[36%]">
                            <div className="flex flex-col ">
                                <div>
                                    <div className="add-ads-on bg-white pl-6 pr-4 py-8 rounded-xl max-h-[450px] overflow-hidden overflow-y-auto">
                                        <div className="flex justify-between items-start border-b pb-3 mb-3">
                                            <h2 className={`flex items-center text-[#333] font-semibold ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`} >{t('menu.add_new_dish.form.options.title')} <span className="ml-2 text-2xl text-mainGreen"><BiEditAlt /></span></h2>
                                            {/* {page==='edit'? <div onClick={()=>setloading(false)}><Button className="text-[12px] text-white capitalize bg-mainColor">Show Adds/Extras</Button></div> : null} */}
                                            <Tooltip
                                                placement="top"
                                                className="text-lg capitalize"
                                                title={t('menu.add_new_dish.form.options.tooltip')}    
                                            >
                                                <Icon className="w-auto h-auto">
                                                    <IoMdHelpCircle className="text-mainColor text-2xl" />
                                                </Icon>
                                            </Tooltip>
                                        </div>
                                        
                                        {optionGroup&&optionGroup?.map((group, index) => (
                                            <div key={group?.id}>
                                                
                                                <div className="[&>input]:w-full  basis-full">
                                                    <div className="flex justify-between items-center capitalize mb-3">
                                                        <p className="text-sm font-semibold">{t('menu.add_new_dish.form.options.option_group')} #{(index + 1)}</p>
                                                        <p className="text-mainRed cursor-pointer text-xl" onClick={() => removeOptionGroupHandler(index)}><IoTrashOutline className="w-full" /></p>
                                                    </div>

                                                    <div className="option-group-form-wrapper w-full flex flex-wrap justify-between items-center border-b pb-2 mt-6 mb-4 [&>div]:mb-2 [&>div>label]:capitalize [&>div>label]:text-[rgb(69,69,69)] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-1 [&>div>label]:font-medium [&>div>input]:bg-[#f3f6f9] [&>div>input]:p-2 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                                                        <div className="[&>input]:w-full basis-[48.5%]">
                                                            <label htmlFor={`group${index}`}>{t('menu.add_new_dish.form.options.group_form.group_name_en')} </label>
                                                            <input
                                                                type="text"
                                                                id={`group${index}`}
                                                                className="text-left"
                                                                {...register(`optionGroups[${index}].optionGroupName`, {
                                                                    required: {
                                                                        value: true,
                                                                        message: t('common.required')
                                                                    },
                                                                    pattern: {
                                                                        value: /^[a-zA-Z0-9&\s]+$/,
                                                                        message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف الانجليزيه والارقام وعلامه & فقط' : 'Only English letters, numbers, and & are allowed'
                                                                    },
                                                                    // minLength : {
                                                                    //     value : 3,
                                                                    //     message : t('common.min_length',{length : '3'})
                                                                    // },
                                                                    // maxLength : {
                                                                    //     value : 50,
                                                                    //     message : t('common.max_length',{length : '50'})
                                                                    // },
                                                                })}
                                                                // minLength={3}
                                                                // maxLength={50}
                                                                defaultValue={group.optionGroupName}
                                                            />
                                                            {errors?.optionGroups?.[index]?.optionGroupName?.message ? <p className="capitalize text-xs text-danger">{errors?.optionGroups?.[index]?.optionGroupName?.message}</p> : <p className="capitalize text-xs text-[#B5B5C3]">{t('menu.add_new_dish.form.options.group_form.group_name_en_helper')}</p>}
                                                        </div>

                                                        <div className="[&>input]:w-full basis-[48.5%]">
                                                            <label htmlFor={`group${index}`}>{t('menu.add_new_dish.form.options.group_form.group_name_ar')} </label>
                                                            <input
                                                                type="text"
                                                                id={`group${index}`}
                                                                className="text-right"
                                                                {...register(`optionGroups[${index}].optionGroupNameAr`, {
                                                                    required: {
                                                                        value: true,
                                                                        message: t('common.required')
                                                                    },
                                                                    pattern: {
                                                                        value: /^[\u0621-\u064A\s&]+$/,
                                                                        message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف العربية فقط' : 'Only Arabic letters are allowed'
                                                                    },
                                                                    // minLength : {
                                                                    //     value : 3,
                                                                    //     message : t('common.min_length',{length : '3'})
                                                                    // },
                                                                    // maxLength : {
                                                                    //     value : 50,
                                                                    //     message : t('common.max_length',{length : '50'})
                                                                    // },
                                                                })}
                                                                // minLength={3}
                                                                // maxLength={50}
                                                                defaultValue={group.optionGroupNameAr}
                                                            />
                                                            {errors?.optionGroups?.[index]?.optionGroupNameAr?.message ? <p className="capitalize text-xs text-danger">{errors?.optionGroups?.[index]?.optionGroupNameAr?.message}</p> : <p className="capitalize text-xs text-[#B5B5C3]">{t('menu.add_new_dish.form.options.group_form.group_name_ar_helper')}</p>}
                                                        </div>

                                                        <div className="w-full basis-full flex justify-between items-center mt-3">
                                                            {/* <CustomSwitch onClick={handleSwitchChange} /> */}
                                                            <Controller
                                                                control={control}
                                                                name={`optionGroups[${index}].multiselect`}
                                                                defaultValue={true}
                                                                render={({ field }) => (
                                                                    <div className="w-full basis-full flex justify-between items-center mt-3">
                                                                        <p className="text-xs font-medium">{t('menu.add_new_dish.form.options.group_form.multi_select')}</p>
                                                                        <CustomSwitch name="multiSelection"  defaultChecked={field.value} onChange={(e)=>{
                                                                            field.onChange(e.target.checked)
                                                                            // handleSwitchChange(e)
                                                                            toggleDisabledInputs(index,!e.target.checked)

                                                                            if(!e.target.checked){
                                                                                setValue(`optionGroups[${index}].minimumSelections`,1)
                                                                                setValue(`optionGroups[${index}].allowSingleOptionMultiselection`,false)
                                                                            } else if(e.target.checked){
                                                                                trigger("minimumSelections")
                                                                            }
                                                                        }} />
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>

                                                        <div className="[&>input]:w-full basis-full">
                                                            <label htmlFor={`group${index}`}>{t('menu.add_new_dish.form.options.group_form.min_selection')} </label>
                                                            <input
                                                                type="text"
                                                                // defaultValue={1}
                                                                id={`group${index}`}
                                                                className="disabled:opacity-50 disabled:bg-gray-400 disabled:cursor-not-allowed"
                                                                {...register(`optionGroups[${index}].minimumSelections`, {
                                                                    required: {
                                                                        value: getValues(`optionGroups[${index}].multiselect`) ? true : false,
                                                                        message: t('common.required')
                                                                    },
                                                                    valueAsNumber : true,
                                                                    // pattern: {
                                                                    //     value: /^[a-zA-Z0-9&\s]+$/,
                                                                    //     message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف الانجليزيه والارقام وعلامه & فقط' : 'Only English letters, numbers, and & are allowed'
                                                                    // },
                                                                    // minLength : {
                                                                    //     value : 3,
                                                                    //     message : t('common.min_length',{length : '3'})
                                                                    // },
                                                                    // maxLength : {
                                                                    //     value : 50,
                                                                    //     message : t('common.max_length',{length : '50'})
                                                                    // },
                                                                    min:{
                                                                        value:1,
                                                                        message : t('menu.add_new_dish.form.options.group_form.min_warning',{value : 1})
                                                                    }
                                                                })}
                                                                min={1}
                                                                // minLength={3}
                                                                // maxLength={50}
                                                                disabled={disabledInput&&disabledInput[index]}
                                                            />
                                                            {errors?.optionGroups?.[index]?.minimumSelections?.message ? <p className="capitalize text-xs text-danger">{errors?.optionGroups?.[index]?.minimumSelections?.message}</p> : <p className="capitalize text-xs text-[#B5B5C3]">{t('menu.add_new_dish.form.options.group_form.min_selection_helper')}</p>}
                                                        </div>

                                                        {getValues(`optionGroups[${index}].multiselect`) === true ? 
                                                            <div className="w-full basis-full flex justify-between items-center">
                                                                <Controller
                                                                    control={control}
                                                                    name={`optionGroups[${index}].allowSingleOptionMultiselection`}
                                                                    defaultValue={false}
                                                                    render={({ field }) => (
                                                                        <div className="w-full basis-full flex justify-between items-center mt-3">
                                                                            <p className="text-xs font-medium">{t('menu.add_new_dish.form.options.group_form.single_option')}</p>
                                                                            <CustomSwitch name="singleSelection" defaultChecked={field.value} onChange={(e)=>{
                                                                                field.onChange(e.target.checked)
                                                                                // handleSwitchChange(e)
                                                                            }} />
                                                                        </div>
                                                                    )}
                                                                />  
                                                            </div> : null }

                                                        <div className="options-wrapper mt-3 capitalize basis-full">
                                                            <Options control={control} register={register} parentIndex={index} errors={errors} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="mt-4 add-option-group-btn flex items-center justify-between">
                                        <Button onClick={addOptionGroupHandler} className="capitalize w-fit text-mainGreen bg-[#5cac7d1a] rounded-md">
                                            <span className="mr-3 text-xl"><IoMdAdd /></span> <span className="text-sm font-[500] py-1">{t('menu.add_new_dish.form.options.group_form.add_btn')}</span>
                                        </Button>

                                        {optionGroup?.length > 1 ?
                                            <div>
                                                <Button onClick={handleOpenPriority} className="bg-transparent border border-solid border-mainYellow text-mainYellow min-w-[130px] capitalize ">{t('common.editPiriorty')}</Button>
                                            </div>

                                            : 

                                            null
                                        }

                                        <PriorityModal open={openPriority} close={handleClosePriority} data={watchedOptionGroup} t={t} move={moveOptionGroup} />
                                    </div> 
                                    
                                </div>

                                <div>
                                    <div className="add-extras bg-white px-6 py-9 rounded-xl mt-9 max-h-[450px] overflow-hidden overflow-y-auto">
                                        <div className="flex justify-between items-start">
                                            <h2 className={`flex ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'} items-center text-[#333] font-semibold mb-5`}>{t('menu.add_new_dish.form.extras.title')}<span className="ml-2 text-2xl text-mainGreen"><BiEditAlt /></span></h2>
                                            {/* {page==='edit'? <div onClick={()=>setloading(false)}><Button className="text-[12px] text-white capitalize bg-mainColor">Show Adds/Extras</Button></div> : null} */}
                                            <Tooltip
                                                placement="top"
                                                className="text-lg capitalize"
                                                title={t('menu.add_new_dish.form.extras.tooltip')}    
                                            >
                                                <Icon className="w-auto h-auto">
                                                    <IoMdHelpCircle className="text-mainColor text-2xl" />
                                                </Icon>
                                            </Tooltip>
                                        </div>
                                        {extras&&extras?.map((field, index) => (
                                            <div key={field?.id} className="w-full flex flex-wrap justify-between items-center border-b pb-2 mt-1 mb-4 [&>div]:mb-2 [&>div>label]:capitalize [&>div>label]:text-[rgb(51,51,51)] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-1 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:p-2 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                                                <div className="[&>input]:w-full basis-full">
                                                    <label>{t('menu.add_new_dish.form.extras.form.name_en')} </label>
                                                    <input type="text" id={`addName${index}`}
                                                        {...register(`extras[${index}].name`, {
                                                            required: {
                                                                value: true,
                                                                message: t('common.required')
                                                            },
                                                            // pattern: {
                                                            //     value: /^[a-zA-Z0-9&\s]+$/,
                                                            //     message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف الانجليزيه والارقام وعلامه & فقط' : 'Only English letters, numbers, and & are allowed'
                                                            // },
                                                            // minLength : {
                                                            //     value : 3,
                                                            //     message : t('common.min_length',{length : '3'})
                                                            // },
                                                            // maxLength : {
                                                            //     value : 50,
                                                            //     message : t('common.max_length',{length : '50'})
                                                            // },
                                                        })}
                                                        // minLength={3}
                                                        // maxLength={50}
                                                        defaultValue={field.name}
                                                    />
                                                    {errors?.extras?.[index]?.name?.message ? <p className="capitalize text-sm text-danger">{errors?.extras?.[index]?.name?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.extras.form.name_en_helper')}</p>}
                                                </div>

                                                <div className="[&>input]:w-full basis-full">
                                                    <label>{t('menu.add_new_dish.form.extras.form.name_ar')} </label>
                                                    <input type="text" id={`addName${index}`}
                                                        {...register(`extras[${index}].nameAr`, {
                                                            required: {
                                                                value: true,
                                                                message: t('common.required')
                                                            },
                                                            // pattern: {
                                                            //     value: /^[\u0621-\u064A\s&]+$/,
                                                            //     message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف العربية والمسافات وعلامه & فقط' : 'Only Arabic letters, spaces, & sign are allowed'
                                                            // },
                                                            // minLength : {
                                                            //     value : 3,
                                                            //     message : t('common.min_length',{length : '3'})
                                                            // },
                                                            // maxLength : {
                                                            //     value : 50,
                                                            //     message : t('common.max_length',{length : '50'})
                                                            // },
                                                        })}
                                                        // minLength={3}
                                                        // maxLength={50}
                                                        defaultValue={field.nameAr}
                                                    />
                                                    {errors?.extras?.[index]?.nameAr?.message ? <p className="capitalize text-sm text-danger">{errors?.extras?.[index]?.nameAr?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.extras.form.name_ar_helper')}</p>}
                                                </div>

                                                <div className="[&>input]:w-full basis-[48%]">
                                                    <label>{t('menu.add_new_dish.form.extras.form.price')} </label>
                                                    <input type="number" id={`addPrice${index}`}
                                                        {...register(`extras[${index}].price`, {
                                                            required: {
                                                                value: true,
                                                                message: t('common.required')
                                                            },
                                                            // min : {
                                                            //     value : 0,
                                                            //     message : t('common.min_value',{value : '0'})
                                                            // },
                                                            // max : {
                                                            //     value : 2000,
                                                            //     message : t('common.max_value',{value : '2000'})
                                                            // }
                                                        })}
                                                        // min={0}
                                                        defaultValue={field.price}
                                                    />
                                                    {errors?.extras?.[index]?.price?.message ? <p className="capitalize text-sm text-danger">{errors?.extras?.[index]?.price?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.extras.form.price_helper')}</p>}
                                                </div>

                                                <div className="[&>input]:w-full basis-[48%]">
                                                    <label>{t('menu.add_new_dish.form.extras.form.calories')} </label>
                                                    <input type="number" id={`addCalories${index}`}
                                                        {...register(`extras[${index}].calories`, {
                                                            required: {
                                                                value: true,
                                                                message: t('common.required')
                                                            },
                                                            // min : {
                                                            //     value : 0,
                                                            //     message : t('common.min_value',{value : '0'})
                                                            // },
                                                            // max : {
                                                            //     value : 50000,
                                                            //     message : t('common.max_value',{value : '50000'})
                                                            // }
                                                        })}
                                                        // min={0}
                                                        defaultValue={field.calories}
                                                    />
                                                    {errors?.extras?.[index]?.calories?.message ? <p className="capitalize text-sm text-danger">{errors?.extras?.[index]?.calories?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.extras.form.calories_helper')}</p>}
                                                </div>

                                                <div className="basis-full">
                                                    <Button onClick={() => removeExtraHandler(index)} className="bg-mainRed text-white p-1 min-w-[35px] capitalize w-full">
                                                        {t('menu.add_new_dish.form.extras.form.remove_btn')}
                                                    </Button>
                                                </div>

                                            </div>
                                        ))}
                                    </div>

                                    <div className="mt-4">
                                        <ListItemButton onClick={addExtraHandler} className="capitalize w-fit text-mainGreen bg-[#5cac7d1a] rounded-md">
                                            <span className="mr-3 text-xl"><IoMdAdd /></span> <span className="text-sm font-[500] py-1">{t('menu.add_new_dish.form.extras.form.add_btn')}</span>
                                        </ListItemButton>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="adds-on-submit-btn basis-full flex justify-end mt-4">
                            <Button type="submit" disabled={categories?.loading || file?.loading} className="disabled:opacity-50 disabled:cursor-not-allowed bg-mainGreen text-white text-base font-bold capitalize w-[200px] py-3 rounded-lg">{t('menu.add_new_dish.form.upload_item')}</Button>
                        </section>

                    </form>
                </section>
            </div>

            {categories?.loading || file?.loading ? <Overlay /> : null}
        </article>
    );
}

export default AddNewDish;