import { useState, useEffect } from 'react';
import { BsDot, BsSortDown, BsSortUp } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { resetSort } from '../Redux/Slice/ResetPagination/resetPagination';

export const useRemoteSort = (apiFunction, dispatch, page, size, searchValue, orderType,selectedDate,resId,status,payoutStatus) => {
    const [sortCount, setSortCount] = useState(0);
    const [col, setCol] = useState(null);
    const [dir, setDir] = useState(null);
    const [icon, seticon] = useState(<BsSortUp />);
    const {sort} = useSelector((state) => state?.resetPagination);

    const handleRemoteSort = (column) => {
        let colName = column?.name;
        if (col === colName && sortCount === 0) {
            setDir('DESC');
            setSortCount(1);
            seticon(<BsSortDown />)
        } else if (col === colName && sortCount === 1) {
            setDir(null);
            setCol(null);
            setSortCount(0);
            seticon(<BsDot className="text-[1px] opacity-0" />)
            // Here, you would reset your data to its original state
        } else {
            setCol(colName);
            setDir('ASC');
            setSortCount(0);
            seticon(<BsSortUp />)
        }
    };

    useEffect(() => {
        if(sort){
            setCol(null);
            setDir(null);
            setSortCount(0);
            seticon(<BsDot className="text-[1px] opacity-0" />)
            dispatch(resetSort({sort:false}));
        } 
    }, [sort])
    

    useEffect(() => {
        dispatch(apiFunction({ page, size, col, dir,resId, searchValue,orderType,selectedDate,status,payoutStatus }));
    }, [apiFunction, col, dir, dispatch, page, searchValue, size, orderType,selectedDate,resId,status,payoutStatus]);

    return { handleRemoteSort,icon };
};
