import { BsTelephone } from "react-icons/bs";
import BreadCrumb from "../../Components/BreadCrumb/breadCrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { Box, Button, FormControl, MenuItem, Modal, Select } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useEffect } from "react";
import { getBackOfficeStuff } from "../../Components/Redux/Slice/Users/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import { assignTicket, toggleTicket } from "../../Components/Redux/Slice/Tickets/tickets";
import { FaUserCheck } from "react-icons/fa";
import TicketTimeline from "../../Components/Timeline/ticketTimeline";
import { useTranslation } from "react-i18next";

const TicketDetails = () => {
    const {t, i18n} = useTranslation()
    const [users, setusers] = useState([])
    const dispatch = useDispatch()
    const {backOfficeStuff} = useSelector(state=>state?.users)
    const {auth} = useSelector(state=>state)
    const location = useLocation()
    const navigate = useNavigate()

    // console.log(location?.state?.data);
    let id = location?.state?.data?.id

    let data = location?.state?.data

    console.log(data);

    const paths = [
        {path:'/dashboard/troubleshoots',name:'troubleshoots', nameAr:'الشكاوى'},
        {path:'',name:`Ticket #${data?.id}`, nameAr:`شكوى #${data?.id}`},
    ]

    const form = useForm()
    const {register,control,handleSubmit,formState,setValue,getValues,reset} = form
    const {errors,isDirty} = formState

    // console.log(errors);
    //Assign Modal 
    const [openModal, setopenModal] = useState(false)
    const handleOpenModal = ()=>setopenModal(true)
    const handleCloseModal = ()=>{
        setopenModal(false)
        reset()
        setusers([])
    }

    const style = {
        position: 'absolute',
        top: '47%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 3,
        p: 0,
        borderRadius : 5
    };

    const handleUserSelectChange = (event) => {
        const { target: { value },} = event;
        setusers(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
        );
        setValue('userId', value,{shouldValidate:true,shouldDirty:true})
    };
    // console.log(auth?.role !=='CUSTOMER_SERVICE' );
    useEffect(() => {
        if(auth?.role === 'SUPER_ADMIN' ){
            dispatch(getBackOfficeStuff())
        }
    }, [dispatch])

    // console.log(backOfficeStuff);
    

    const submitHandler = async (userId)=>{
        await dispatch(assignTicket({userId,id})).then( (e)=>{
            // if(e?.payload?.message === "SUCCESS"){
            //     navigate(-1)
            // }
            handleCloseModal()
        } )
        // console.log(data);

    }

    // resolve modal

    const [open, setopen] = useState(false);
    const [type, settype] = useState('');

    const resolveStyle = {
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        minWidth : '500px',
        height: 'auto',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 2,
        py: 4,
        px:2,
        borderRadius : 5,
    };
    
    useEffect(() => {
        reset()
    }, [type])
    
    const handleOpen = (e) => {
        setopen(true)
        settype(e);
        // console.log(e);
    };
    const handleClose = () => {
        setopen(false)
        reset()
    };

    const submitResolveHandler = async (values)=>{        
        await dispatch(toggleTicket({values,id,type})).then( (e)=>{
            if(e?.payload?.message === "SUCCESS"){
                handleClose()
                reset()
                navigate('/dashboard/troubleshoots')
            }
        } )
    }

    return ( 

        // <article className="ticket-details-wrapper">
        //     <section className="ticket-title-wrapper">
        //         <h1 className="capitalize text-[#333] font-extrabold text-3xl mb-2">Ticket #{data?.id} <span className={` capitalize font-bold ${data?.status === 'OPEN' ? 'text-mainRed' : data?.status === 'CLOSED' ? 'text-mainColor' : data?.status === 'RESOLVED' ? 'text-mainGreen' : data?.status === 'REOPENED' ? 'text-[#c88256]' : ''}`}>{data?.status?.toLowerCase()}</span></h1>

        //         <div>
        //             <BreadCrumb paths={paths} seperator='/' />
        //         </div>
        //     </section>

        //     {auth?.role === 'SUPER_ADMIN' ? 
        //         <section className="assign-ticket-wrapper w-full text-right mb-4">
        //             <Button className="capitalize text-white bg-mainColor" onClick={handleOpenModal}>
        //                 assign ticket
        //             </Button>
        //         </section>
        //         : auth?.role === 'BACK_OFFICE' && (data?.status === 'OPEN' || data?.status === 'REOPENED')?
        //             <section className="assign-ticket-wrapper w-full text-right mb-4 flex items-center justify-end gap-x-3">
        //                 <Button className="capitalize text-white bg-mainGreen py-1 px-4 rounded-md" onClick={()=>handleOpen('resolve')} >
        //                     Resolve
        //                 </Button>

        //                 <Button className="capitalize text-white bg-mainColor py-1 px-4 rounded-md" onClick={()=>handleOpen('close')}>
        //                     Close
        //                 </Button>
        //             </section>
        //         : null
        //     }  

        //     <section className="ticket-info-wrapper">
        //         <div className="ticket-info p-4 bg-white my-3 mb-8 capitalize  rounded-lg [&>div]:mb-2 [&>div>label]:mr-1 [&>div>label]:font-semibold [&>div>label]:text-base [&>div>label]:text-mainColor [&>div>span]:text-[#333] [&>div>span]:text-sm">
        //             <div>
        //                 <label>Type: </label>
        //                 <span>{data?.type?.replace(/_/g," ")?.toLowerCase()}</span>
        //             </div>

        //             <div>
        //                 <label>reason: </label>
        //                 <span>{data?.reason ? data?.reason : 'None'}</span>
        //             </div>

        //             <div>
        //                 <label>resolution: </label>
        //                 <span>{data?.reason ? data?.reason : 'None'}</span>
        //             </div>
        //         </div>

        //         <div className="flex flex-wrap justify-between items-center mb-10">
        //             {data?.restaurant ?
                    
        //                 <div className={`restaurant-info-wrapper basis-full mb-4 ${data?.driver ? 'md:basis-[30%]' : 'md:basis-[48%]'} bg-white rounded-lg pt-3`}>
        //                     <p className="text-[#333] text-lg font-semibold capitalize mb-2 px-3">Restaurant info</p>
        //                     <div className="customer-picture w-full">
        //                         <div className="m-auto">
        //                             {data?.restaurant?.logoUrl ? <img src={data?.restaurant?.logoUrl} alt="customer-profile" className="w-[100px] h-[100px] rounded-[50%] m-auto" /> : <span className="block bg-gray-300 w-[100px] h-[100px] rounded-[50%] m-auto"></span>}
        //                         </div>
        //                     </div>

        //                     <div className="customer-details mt-3 p-3 mb-8">
        //                         <p className="font-semibold text-md text-mainColor capitalize">
        //                             {data?.restaurant?.name}
        //                         </p>

        //                         <div className="mt-6">

        //                             <div className="flex items-center mb-4">
        //                                 <div className="mr-4">
        //                                     <span className="w-8 h-8 rounded-[50%] bg-mainColor text-center flex items-center"><BsTelephone className="m-auto text-white text-lg" /></span>
        //                                 </div>
        //                                 <div className="capitalize">
        //                                     <p className="text-[#B5B5C3] text-[12px]">telephone</p>
        //                                     <p className="text-[#464E5F]">{data?.restaurant?.phoneNumber}</p>
        //                                 </div>
        //                             </div>

        //                             <div className="flex items-center">
        //                                 <div className="mr-4">
        //                                     <span className="w-8 h-8 rounded-[50%] bg-mainColor text-center flex items-center"><MdOutlineAlternateEmail className="m-auto text-white text-lg" /></span>
        //                                 </div>
        //                                 <div className="">
        //                                     <p className="text-[#B5B5C3] text-[12px] capitalize">email</p>
        //                                     <p className="text-[#464E5F]">{data?.restaurant?.email}</p>
        //                                 </div>
        //                             </div>
        //                         </div>

        //                     </div>

        //                     <div className="relative bottom-0 left-0 right-0 bg-mainColor px-4 py-3 rounded-lg">
        //                         <div className="flex items-center">
        //                             <div>
        //                                 <span className="w-10 h-10 rounded-[50%] bg-white text-center flex items-center"><TbDeviceLandlinePhone className="m-auto text-mainColor text-xl" /></span>
        //                             </div>

        //                             <div className="text-[12px] text-white ml-4 font-semibold capitalize">
        //                                 {/* <p>12 Rehab St, 23 Building 12</p>
        //                                 <p>Apartment Jeddah</p> */}
        //                                 <p>{data?.restaurant?.hotline}</p>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 :
        //                 <p className="bg-white rounded-lg min-h-[310px] flex justify-center items-center p-4 basis-full md:basis-[35%] text-center font-semibold text-gray-600 text-lg capitalize">no restaurant info.</p>
        //             }

        //             {data?.customer ? 
        //                 <div className={`customer-info-wrapper basis-full mb-4 ${data?.driver ? 'md:basis-[30%]' : 'md:basis-[48%]'} bg-white rounded-lg pt-3`}>
        //                     <p className="text-[#333] text-lg font-semibold capitalize mb-2 px-3">customer info</p>
        //                     <div className="customer-picture w-full">
        //                         <div className="m-auto">
        //                             <img src={user2} alt="customer-profile" className="w-[100px] h-[100px] rounded-[50%] m-auto" />
        //                         </div>
        //                     </div>

        //                     <div className="customer-details mt-3  p-3 mb-8">
        //                         <p className="font-semibold text-md text-mainColor capitalize">
        //                             {data?.customer?.firstName + " " + data?.customer?.lastName}
        //                         </p>

        //                         <div className="mt-6">

        //                             <div className="flex items-center mb-4">
        //                                 <div className="mr-4">
        //                                     <span className="w-8 h-8 rounded-[50%] bg-mainColor text-center flex items-center"><BsTelephone className="m-auto text-white text-lg" /></span>
        //                                 </div>
        //                                 <div className="capitalize">
        //                                     <p className="text-[#B5B5C3] text-[12px]">telephone</p>
        //                                     <p className="text-[#464E5F]">{data?.customer?.mobileNumber}</p>
        //                                 </div>
        //                             </div>

        //                             <div className="flex items-center">
        //                                 <div className="mr-4">
        //                                     <span className="w-8 h-8 rounded-[50%] bg-mainColor text-center flex items-center"><MdOutlineAlternateEmail className="m-auto text-white text-lg" /></span>
        //                                 </div>
        //                                 <div className="">
        //                                     <p className="text-[#B5B5C3] text-[12px] capitalize">email</p>
        //                                     <p className="text-[#464E5F]">{data?.customer?.email}</p>
        //                                 </div>
        //                             </div>
        //                         </div>

        //                     </div>

        //                     <div className="relative bottom-0 left-0 right-0 bg-mainColor px-4 py-3 rounded-lg">
        //                         <div className="flex items-center">
        //                             <div>
        //                                 <span className="w-10 h-10 rounded-[50%] bg-white text-center flex items-center"><CiLocationOn className="m-auto text-mainColor text-xl" /></span>
        //                             </div>

        //                             <div className="text-[12px] text-white ml-4 font-semibold capitalize">
        //                                 {/* <p>12 Rehab St, 23 Building 12</p>
        //                                 <p>Apartment Jeddah</p> */}
        //                                 <p>{data?.customer?.addresses && data?.customer?.addresses?.length !==0 ? data?.customer?.addresses : 'No Address Found'}</p>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 :
        //                 <p className="bg-white rounded-lg min-h-[310px] flex justify-center items-center p-4 basis-full md:basis-[35%] text-center font-semibold text-gray-600 text-lg capitalize">no customer info.</p>
        //             }

        //             {data?.driver ? 

        //                 <>
        //                     <div className="customer-info-wrapper basis-full md:basis-[30%] bg-white rounded-lg pt-3 mb-4">
        //                         <p className="text-[#333] text-lg font-semibold capitalize mb-2 px-3">driver info</p>
        //                         <div className="customer-picture w-full">
        //                             <div className="m-auto">
        //                                 <img src={user2} alt="customer-profile" className="w-[100px] h-[100px] rounded-[50%] m-auto" />
        //                             </div>
        //                         </div>

        //                         <div className="customer-details mt-3  p-3 mb-8">
        //                             <p className="font-semibold text-md text-mainColor capitalize">
        //                                 {data?.driver?.firstName + " " + data?.driver?.lastName}
        //                             </p>

        //                             <div className="mt-6">

        //                                 <div className="flex items-center mb-4">
        //                                     <div className="mr-4">
        //                                         <span className="w-8 h-8 rounded-[50%] bg-mainColor text-center flex items-center"><BsTelephone className="m-auto text-white text-lg" /></span>
        //                                     </div>
        //                                     <div className="capitalize">
        //                                         <p className="text-[#B5B5C3] text-[12px]">telephone</p>
        //                                         <p className="text-[#464E5F]">{data?.driver?.mobileNumber}</p>
        //                                     </div>
        //                                 </div>

        //                                 <div className="flex items-center">
        //                                     <div className="mr-4">
        //                                         <span className="w-8 h-8 rounded-[50%] bg-mainColor text-center flex items-center"><MdOutlineAlternateEmail className="m-auto text-white text-lg" /></span>
        //                                     </div>
        //                                     <div className="">
        //                                         <p className="text-[#B5B5C3] text-[12px] capitalize">email</p>
        //                                         <p className="text-[#464E5F]">{data?.driver?.email}</p>
        //                                     </div>
        //                                 </div>
        //                             </div>

        //                         </div>

        //                         <div className="relative bottom-0 left-0 right-0 bg-mainColor px-4 py-3 rounded-lg">
        //                             <div className="flex items-center">
        //                                 <div>
        //                                     <span className="w-10 h-10 rounded-[50%] bg-white text-center flex items-center"><CiLocationOn className="m-auto text-mainColor text-xl" /></span>
        //                                 </div>

        //                                 <div className="text-[12px] text-white ml-4 font-semibold capitalize">
        //                                     {/* <p>12 Rehab St, 23 Building 12</p>
        //                                     <p>Apartment Jeddah</p> */}
        //                                     <p>{data?.driver?.addresses && data?.driver?.addresses?.length !==0 ? data?.driver?.addresses : 'No Address Found'}</p>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div> 
        //                 </>
                        
        //             : null}

        //         </div>

        //         <div className="flex justify-between items-center by-to-wrapper mb-10">
        //             <div className="createdby-wrapper bg-white rounded-lg py-4 basis-full md:basis-[35%]">
        //                 <div className="customer-info-wrapper px-4 flex flex-wrap justify-between items-center mb-6">
        //                     <div className="customer-details capitalize text-[#464E5F] font-semibold mb-2 md:mb-0">
        //                         <p className="text-lg mb-1">created by</p>
        //                         <p className="text-md text-mainColor">{data?.createdBy?.firstName + " " + data?.createdBy?.lastName}</p>
        //                     </div>
        //                 </div>

        //                 <div className="px-4 customer-contact-wrapper mt-2">

        //                     <div className="flex items-center mb-4">
        //                         <div className="mr-4">
        //                             <span className="w-10 h-10 rounded-[50%] bg-mainYellow text-center flex items-center"><BsTelephone className="m-auto text-white text-xl" /></span>
        //                         </div>
        //                         <div className="capitalize">
        //                             <p className="text-[#B5B5C3] text-[12px]">telephone</p>
        //                             <p className="text-[#464E5F]">{data?.createdBy?.mobileNumber}</p>
        //                         </div>
        //                     </div>

        //                     <div className="flex items-center">
        //                         <div className="mr-4">
        //                             <span className="w-10 h-10 rounded-[50%] bg-mainYellow text-center flex items-center"><MdOutlineAlternateEmail className="m-auto text-white text-xl" /></span>
        //                         </div>
        //                         <div className="">
        //                             <p className="text-[#B5B5C3] text-[12px] capitalize">email</p>
        //                             <p className="text-[#464E5F]">{data?.createdBy?.email}</p>
        //                         </div>
        //                     </div>
        //                 </div>

        //                 {/* <div className="address-wrapper mt-12">
        //                     <div className="relative bg-[#3E4954] rounded-lg">

        //                         <div className="relative bottom-0 left-0 right-0 bg-mainYellow px-4 py-3 rounded-lg">
        //                             <div className="flex items-center">
        //                                 <div>
        //                                     <span className="w-10 h-10 rounded-[50%] bg-white text-center flex items-center"><CiLocationOn className="m-auto text-mainYellow text-xl" /></span>
        //                                 </div>

        //                                 <div className="text-[12px] text-white ml-4 font-semibold capitalize">
        //                                     <p>{data?.createdBy?.addresses && data?.createdBy?.addresses?.length !==0 ? data?.createdBy?.addresses : 'No Address Found'}</p>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div> */}
        //             </div>

        //             <div className="text-8xl text-mainColor">
        //                 <BsFillArrowRightCircleFill />
        //             </div>

        //             { data?.assignedTo ? 
        //                 <div className="assignedTo-wrapper bg-white rounded-lg py-4 basis-full md:basis-[35%]">
        //                     <div className="customer-info-wrapper px-4 flex flex-wrap justify-between items-center mb-6">
        //                         <div className="customer-details capitalize text-[#464E5F] font-semibold mb-2 md:mb-0">
        //                             <p className="text-lg mb-1">assigned to</p>
        //                             <p className="text-md text-mainColor">{data?.assignedTo?.firstName + " " + data?.assignedTo?.lastName}</p>
        //                         </div>
        //                     </div>

        //                     <div className="px-4 customer-contact-wrapper mt-2">

        //                         <div className="flex items-center mb-4">
        //                             <div className="mr-4">
        //                                 <span className="w-10 h-10 rounded-[50%] bg-mainYellow text-center flex items-center"><BsTelephone className="m-auto text-white text-xl" /></span>
        //                             </div>
        //                             <div className="capitalize">
        //                                 <p className="text-[#B5B5C3] text-[12px]">telephone</p>
        //                                 <p className="text-[#464E5F]">{data?.assignedTo?.mobileNumber}</p>
        //                             </div>
        //                         </div>

        //                         <div className="flex items-center">
        //                             <div className="mr-4">
        //                                 <span className="w-10 h-10 rounded-[50%] bg-mainYellow text-center flex items-center"><MdOutlineAlternateEmail className="m-auto text-white text-xl" /></span>
        //                             </div>
        //                             <div className="">
        //                                 <p className="text-[#B5B5C3] text-[12px] capitalize">email</p>
        //                                 <p className="text-[#464E5F]">{data?.assignedTo?.email}</p>
        //                             </div>
        //                         </div>
        //                     </div>

        //                     {/* <div className="address-wrapper mt-12">
        //                         <div className="relative bg-[#3E4954] rounded-lg">
        //                             <div className="relative bottom-0 left-0 right-0 bg-mainYellow px-4 py-3 rounded-lg">
        //                                 <div className="flex items-center">
        //                                     <div>
        //                                         <span className="w-10 h-10 rounded-[50%] bg-white text-center flex items-center"><CiLocationOn className="m-auto text-mainYellow text-xl" /></span>
        //                                     </div>

        //                                     <div className="text-[12px] text-white ml-4 font-semibold capitalize">
        //                                         <p>{data?.assignedTo?.addresses && data?.assignedTo?.addresses?.length !==0 ? data?.assignedTo?.addresses : 'No Address Found'}</p>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div> */}
        //                 </div>
        //                 : <p className="bg-white rounded-lg min-h-[310px] flex justify-center items-center p-4 basis-full md:basis-[35%] text-center font-semibold text-gray-600 text-lg capitalize">not assigned yet.</p>
        //             }
        //         </div>

        //     </section>


        //     <>
        //         {
        //             auth?.role === 'SUPER_ADMIN' ? 
        //             <Modal
        //                 open={openModal}
        //                 onClose={handleCloseModal}
        //                 aria-labelledby="menu-item-delete-modal-title"
        //                 aria-describedby="menu-item-delete-modal-description"
        //             >
        //                 <Box sx={style}>
        //                     <form onSubmit={handleSubmit(submitHandler)} noValidate className='py-3 px-1'>
        //                         <div className="flex justify-between items-center mb-8 border-b px-4 pb-0 pt-5">
        //                             <h2 className="text-[#333333] text-lg font-bold mb-5 capitalize">Assign Ticket</h2>

        //                             <div>
        //                                 <Button color="error" className="text-2xl -mt-2" onClick={handleCloseModal}><IoCloseCircleOutline /></Button>
        //                             </div>
        //                         </div>

        //                         <div className='p-5 py-0 flex flex-wrap justify-between items-center md:gap-x-4 [&>div]:mb-3 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1'>
        //                             <div className="basis-full">
        //                                 <label>Assign To</label>
        //                                 <FormControl sx={{width: '100%' }}>
        //                                     <Controller
        //                                         control={control}
        //                                         name=""
        //                                         value={users}
        //                                         rules={{required:"Must Select An Option"}}
        //                                         {...register('userId',{
        //                                             required:{
        //                                                 value : true,
        //                                                 message: 'This field is required'
        //                                             }
        //                                         })}
        //                                         render={({field,fieldState: { error }})=>(
        //                                             <>
        //                                                 <Select {...field}
        //                                                     displayEmpty
        //                                                     inputRef={{...register('userId')}}
        //                                                     labelId="demo-multiple-checkbox-label"
        //                                                     id="userId"
        //                                                     value={users}
        //                                                     onChange={handleUserSelectChange}
        //                                                     // renderValue={(selected) => selected.join(', ')}
        //                                                     sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'11px 14px'},'& fieldset':{border : 0},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'}}}
        //                                                 >
        //                                                     <MenuItem value={''} disabled>
        //                                                         Select User
        //                                                     </MenuItem>
        //                                                     {backOfficeStuff?.data?.map( (user)=>(
        //                                                         <MenuItem value={user?.id} key={user?.id}>
        //                                                             {/* <ListItemText className='capitalize' primary={user?.username?.toLowerCase()?.replace(/_/g," ")} /> */}
        //                                                             <div className="[&>span]:block [&>span]:w-full">
        //                                                                 <span className="capitalize">{user?.username?.toLowerCase()?.replace(/_/g," ")}</span>
        //                                                                 <span className="text-sm text-gray-400">{user?.email}</span>
        //                                                             </div>
        //                                                         </MenuItem>
        //                                                     ) )}
                                                            

        //                                                 </Select>                                                    
        //                                             </>
        //                                         )}
                                                
        //                                     />
                                            
        //                                 </FormControl>

        //                                 {errors?.userId?.message ? <p className="capitalize text-sm text-danger mt-1">{errors?.userId?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3] mt-1">please select User</p>}
        //                             </div>
        //                         </div>

        //                         <div className='add-user-submit-btn flex justify-end w-full mt-7 px-4 py-2'>
        //                             <Button type='submit' disabled={users?.loading } className='disabled:opacity-50 disabled:cursor-not-allowed capitalize bg-mainColor text-white w-28'>Assign</Button>
        //                         </div>
        //                     </form>
        //                 </Box>
        //             </Modal>
        //             :
        //             auth?.role === 'BACK_OFFICE' ? 

        //             <Modal
        //                 open={open}
        //                 onClose={handleClose}
        //                 aria-labelledby="modal-modal-title"
        //                 aria-describedby="modal-modal-description"
        //             >
        //                 <Box sx={resolveStyle}>
        //                     <div className="flex justify-between items-center border-b pb-2 mb-3">
        //                         <h1 className="text-xl text-[#333] capitalize font-semibold">
        //                             {type === 'resolve' ? 'Ticket Resolution' : type === 'close' ? 'Close Ticket' : type === 're-open' ? 'Re-open' : ''}
                                    
        //                         </h1>

        //                         {/* <div>
        //                             <FaTimesCircle onClick={handleClearanceClose} className="text-mainRed text-xl cursor-pointer" />
        //                         </div> */}
        //                     </div>

        //                     <form noValidate onSubmit={handleSubmit(submitResolveHandler)}>
        //                         <div className=" [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>textarea]:bg-[#f3f6f9] [&>div>textarea]:w-full [&>div>textarea]:p-3 [&>div>textarea]:focus:outline-slate-400 [&>div>textarea]:rounded-md [&>div>textarea]:text-mainColor [&>div>textarea]:mb-1 [&>div>textarea]:resize-none">
                                    
        //                             <div>
        //                                 <label> {type === 'resolve' ? 'summary' : type === 'close' ? 'Reason' : type === 're-open' ? 'Reason' : '' } </label>
        //                                 <textarea
        //                                     id={`${type === 'resolve' ? 'resolution' : type === 'close' ? 'reason' : type === 're-open' ? 'reason' : ''}`}
        //                                     {...register(`${type === 'resolve' ? 'resolution' : type === 'close' ? 'reason' : type === 're-open' ? 'reason' : ''}`,{
        //                                         required:{
        //                                             value : true,
        //                                             message : 'This field is required'
        //                                         }
        //                                     })}
        //                                 />
                                        
        //                                 { 
        //                                     type === 'resolve' ? 
        //                                         <p className="capitalize text-sm text-danger">{errors?.resolution?.message}</p>
        //                                     :
        //                                     type === 'close' ? 
        //                                         <p className="capitalize text-sm text-danger">{errors?.reason?.message}</p>
        //                                     :
        //                                     type === 're-open' ? 
        //                                         <p className="capitalize text-sm text-danger">{errors?.reason?.message}</p>
        //                                     : null
        //                                 }
        //                             </div>
                                    
        //                             <div className="flex justify-end item-center gap-x-4 mt-6 mb-0 capitalize text-sm [&>button]:text-white [&>button]:rounded-lg [&>button]:px-4">
        //                                 <Button className="bg-gray-400" onClick={handleClose}>Cancel</Button>
        //                                 <Button className="bg-mainColor disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed" type="submit" disabled={''}>submit</Button>
        //                             </div>
        //                         </div>
        //                     </form>
        //                 </Box>
        //             </Modal>
        //             : null
        //         }
        //     </>
        // </article>

        <article className="ticket-details-wrapper">    
            <section className="ticket-title-wrapper">
                <h1 className="capitalize text-[#333] font-extrabold text-3xl mb-2">{t('trouble_tickets.ticket_details.title')}{data?.id}</h1>

                <div>
                    <BreadCrumb paths={paths} seperator='/' />
                </div>
            </section>

            <section className="flex justify-between gap-x-3 [&>div]:basis-[49%]">
                <div className="ticket-details-wrapper bg-white rounded-md p-3">
                    <div className={`flex justify-between items-center ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                        <p className="font-semibold capitalize text-[#333]">{t('trouble_tickets.ticket_details.ticket_details')} <span className={` capitalize font-bold text-xs p-[6px] rounded-full ${data?.status === 'OPEN' ? 'text-mainRed bg-[rgba(217,29,89,0.14)]' : data?.status === 'CLOSED' ? 'text-mainColor bg-[rgba(68,43,126,0.14)]' : data?.status === 'RESOLVED' ? 'text-mainGreen bg-[rgba(54,176,123,0.14)]' : data?.status === 'REOPENED' ? 'text-[#c88256] bg-[rgba(225,172,66,0.14)]' : ''}`}>
                            {
                                data?.status === 'CLOSED' ? t('common.closed') :
                                data?.status === 'OPEN' ? t('common.open') :
                                data?.status === 'RESOLVED' ? t('common.resolved') :
                                data?.status === 'REOPENED' ? t('common.reopened') : null

                                // localStorage.getItem('lang') === 'ar' ? data?.statusAr : data?.status
                            }</span>
                        </p>

                        <div>
                            {auth?.role === 'SUPER_ADMIN' ? 
                                <section className="assign-ticket-wrapper w-full text-right">
                                    <Button className="capitalize text-white text-sm bg-mainColor flex gap-x-2 items-center px-4 py-3" onClick={handleOpenModal}>
                                        <FaUserCheck className="text-lg" /> {t('trouble_tickets.ticket_details.assign_ticket')}
                                    </Button>
                                </section>
                                : auth?.role === 'BACK_OFFICE' && (data?.status === 'OPEN' || data?.status === 'REOPENED')?
                                    <section className="assign-ticket-wrapper w-full text-right flex items-center justify-end gap-x-3">
                                        <Button className="capitalize text-white bg-mainGreen py-1 px-4 rounded-md" onClick={()=>handleOpen('resolve')} >
                                            {t('trouble_tickets.assign_modal.form.resolve')}
                                        </Button>

                                        <Button className="capitalize text-white bg-mainColor py-1 px-4 rounded-md" onClick={()=>handleOpen('close')}>
                                            {t('trouble_tickets.assign_modal.form.close')}    
                                        </Button>
                                    </section>
                                : null }  
                        </div>
                    </div>

                    <div className="mt-3 capitalize [&>div]:mb-2 [&>div>label]:block [&>div>label]:font-semibold [&>div>label]:text-[#333] [&>div>span]:text-sm [&>div>span]:text-[#999999]">
                        <div>
                            <label>{t('trouble_tickets.ticket_details.type')}</label>
                            <span>{localStorage.getItem('lang') === 'ar' ? data?.typeAr : data?.type?.replace(/_/g," ")?.toLowerCase()}</span>
                        </div>

                        <div>
                            <label>{t('trouble_tickets.ticket_details.reason')}</label>
                            <span>{data?.reason ? data?.reason : t('common.none')}</span>
                        </div>

                        <div>
                            <label>{t('trouble_tickets.ticket_details.resolution')}</label>
                            <span>{data?.resolution ? data?.resolution : t('common.none')}</span>
                        </div>

                        <div>
                            <label>{t('trouble_tickets.ticket_details.description')}</label>
                            <span>{data?.description ? data?.description : t('common.none')}</span>
                        </div>
                    </div>
                </div>

                <div className="restaurant-info-wrapper bg-white rounded-md p-3">
                    <p className="text-[#333] capitalize font-semibold mt-2">{t('trouble_tickets.ticket_details.restaurant_info')}</p>

                    <div className="flex items-center gap-x-3 mt-2">
                        <div>
                            {data?.restaurantLogo ? <img src={data?.restaurantLogo} alt="restuarant-logo" className="w-[190px] h-[190px] rounded-[50%] m-auto" /> : <span className="bg-gray-300 w-[190px] h-[190px] rounded-[50%] m-auto capitalize flex items-center justify-center">{t('trouble_tickets.ticket_details.no_image')}</span>}
                        </div>

                        <div className="customer-details mt-2 p-3">
                            <p className="font-semibold text-md text-mainColor capitalize">
                                {data?.restaurantName ? data?.restaurantName : t('trouble_tickets.ticket_details.no_restaurant')}
                            </p>

                            <div className="mt-[9px]">
                                <div className="flex items-center mb-2 gap-x-4">
                                    <div>
                                        <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><BsTelephone className="m-auto text-white text-lg" /></span>
                                    </div>
                                    
                                    <div className="capitalize">
                                        <p className="text-[#B5B5C3] text-[12px]">{t('trouble_tickets.ticket_details.telephone')}</p>
                                        <p className="text-[#464E5F] text-sm font-semibold" dir="ltr">{data?.branchNumber ? data?.branchNumber : t('trouble_tickets.ticket_details.no_telephone')}</p>
                                    </div>
                                </div>

                                <div className="flex items-center mb-2 gap-x-4">
                                    <div>
                                        <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><MdOutlineAlternateEmail className="m-auto text-white text-lg" /></span>
                                    </div>
                                    <div>
                                        <p className="text-[#B5B5C3] text-[12px] capitalize">{t('trouble_tickets.ticket_details.email')}</p>
                                        <p className="text-[#464E5F] text-sm font-semibold">{data?.restaurantEmail ? data?.restaurantEmail : t('trouble_tickets.ticket_details.no_email')}</p>
                                    </div>
                                </div>

                                {/* <div className="flex items-center">
                                    <div className="mr-4">
                                        <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><TbDeviceLandlinePhone className="m-auto text-white text-lg" /></span>
                                    </div>

                                    <div className="">
                                        <p className="text-[#B5B5C3] text-[12px] capitalize">hotline</p>
                                        <p className="text-[#464E5F] text-sm font-semibold">{data?.restaurant?.hotline ? data?.restaurant?.hotline : 'restaurant hotline not found'}</p>
                                    </div>
                                </div> */}
                                
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className="personal-info-wrapper my-5 flex flex-col rounded-md p-3 bg-white">
                <div className="customer-info-wrapper pb-5 border-b border-gray-300">
                    <p className="text-[#333] capitalize font-semibold mt-2">{t('trouble_tickets.ticket_details.customer_info')}</p>

                    <div className="flex items-center gap-x-3 mt-2">
                        {/* <div>
                            {data?.customer?.logoUrl ? <img src={data?.customer?.logoUrl} alt="customer-profile" className="w-[190px] h-[190px] rounded-[50%] m-auto" /> : <span className="bg-gray-300 w-[190px] h-[190px] rounded-[50%] m-auto capitalize flex items-center justify-center">no image found</span>}
                        </div> */}

                        <div className="customer-details mt-2 p-3">
                            <p className="font-semibold text-md text-mainColor capitalize">
                                {data?.customerName ? data?.customerName : t('trouble_tickets.ticket_details.no_customer')}
                            </p>

                            <div className="mt-[9px]">
                                <div className="flex items-center mb-2 gap-x-4">
                                    <div>
                                        <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><BsTelephone className="m-auto text-white text-lg" /></span>
                                    </div>
                                    
                                    <div className="capitalize">
                                        <p className="text-[#B5B5C3] text-[12px]">{t('trouble_tickets.ticket_details.telephone')}</p>
                                        <p className="text-[#464E5F] text-sm font-semibold" dir="ltr">{data?.customerMobileNumber ? data?.customerMobileNumber : t('trouble_tickets.ticket_details.no_telephone')}</p>
                                    </div>
                                </div>

                                {/* <div className="flex items-center mb-2 gap-x-4">
                                    <div>
                                        <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><MdOutlineAlternateEmail className="m-auto text-white text-lg" /></span>
                                    </div>
                                    <div>
                                        <p className="text-[#B5B5C3] text-[12px] capitalize">email</p>
                                        <p className="text-[#464E5F] text-sm font-semibold">{data?.customer?.email ? data?.customer?.email : 'customer email not found'}</p>
                                    </div>
                                </div> */}

                                {/* <div className="flex items-center">
                                    <div>
                                        <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><MdLocationPin className="m-auto text-white text-lg" /></span>
                                    </div>

                                    <div>
                                        <p className="text-[#B5B5C3] text-[12px] capitalize">address</p>
                                        <p className="text-[#464E5F] text-sm font-semibold">{data?.customer?.addresses && data?.customer?.addresses?.length !==0 ? data?.customer?.addresses : 'No Address Found'}</p>
                                    </div>
                                </div> */}
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div className="driver-info-wrapper mt-2">
                    <p className="text-[#333] capitalize font-semibold mt-2">{t('trouble_tickets.ticket_details.driver_info')}</p>

                    <div className="flex items-center gap-x-3 mt-2">
                        {/* <div>
                            {data?.courierImage ? <img src={data?.courierImage} alt="driver-profile" className="w-[190px] h-[190px] rounded-[50%] m-auto" /> : <span className="bg-gray-300 w-[190px] h-[190px] rounded-[50%] m-auto capitalize flex items-center justify-center">no image found</span>}
                        </div> */}

                        <div className="driver-details mt-2 p-3">
                            <p className="font-semibold text-md text-mainColor capitalize">
                                {data?.courierName ? data?.courierName : t('trouble_tickets.ticket_details.no_driver')}
                            </p>

                            <div className="mt-[9px]">
                                <div className="flex items-center mb-2 gap-x-4">
                                    <div>
                                        <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><BsTelephone className="m-auto text-white text-lg" /></span>
                                    </div>
                                    
                                    <div className="capitalize">
                                        <p className="text-[#B5B5C3] text-[12px]">{t('trouble_tickets.ticket_details.telephone')}</p>
                                        <p className="text-[#464E5F] text-sm font-semibold" dir="ltr">{data?.courierMobileNumber ? data?.courierMobileNumber : t('trouble_tickets.ticket_details.no_telephone')}</p>
                                    </div>
                                </div>

                                {/* <div className="flex items-center mb-2 gap-x-4">
                                    <div>
                                        <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><MdOutlineAlternateEmail className="m-auto text-white text-lg" /></span>
                                    </div>
                                    <div>
                                        <p className="text-[#B5B5C3] text-[12px] capitalize">email</p>
                                        <p className="text-[#464E5F] text-sm font-semibold">{data?.driver?.email ? data?.driver?.email : 'driver email not found'}</p>
                                    </div>
                                </div>

                                <div className="flex items-center">
                                    <div>
                                        <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><MdLocationPin className="m-auto text-white text-lg" /></span>
                                    </div>

                                    <div>
                                        <p className="text-[#B5B5C3] text-[12px] capitalize">address</p>
                                        <p className="text-[#464E5F] text-sm font-semibold">{data?.driver?.addresses && data?.driver?.addresses?.length !==0 ? data?.driver?.addresses : 'No Address Found'}</p>
                                    </div>
                                </div> */}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ticket-updates-timeline mt-5 rounded-md p-3 bg-white">
                <p className="font-semibold capitalize text-[#333]">{t('trouble_tickets.ticket_details.ticket_updates')}</p>

                <div className="mt-4">
                    <TicketTimeline createdBy={data?.createdBy} assignedTo={data?.assignedTo} t={t} />
                </div>
            </section>

            <>
                { auth?.role === 'SUPER_ADMIN' ? 
                    // <Modal
                    //     open={openModal}
                    //     onClose={handleCloseModal}
                    //     aria-labelledby="menu-item-delete-modal-title"
                    //     aria-describedby="menu-item-delete-modal-description"
                    // >
                    //     <Box sx={style}>
                    //         <form onSubmit={handleSubmit(submitHandler)} noValidate className='py-3 px-1'>
                    //             <div className="flex justify-between items-center mb-8 border-b px-4 pb-0 pt-5">
                    //                 <h2 className="text-[#333333] text-lg font-bold mb-5 capitalize">Assign Ticket</h2>

                    //                 <div>
                    //                     <Button color="error" className="text-2xl -mt-2" onClick={handleCloseModal}><IoCloseCircleOutline /></Button>
                    //                 </div>
                    //             </div>

                    //             <div className='p-5 py-0 flex flex-wrap justify-between items-center md:gap-x-4 [&>div]:mb-3 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1'>
                    //                 <div className="basis-full">
                    //                     <label>Assign To</label>
                    //                     <FormControl sx={{width: '100%' }}>
                    //                         <Controller
                    //                             control={control}
                    //                             name=""
                    //                             value={users}
                    //                             rules={{required:"Must Select An Option"}}
                    //                             {...register('userId',{
                    //                                 required:{
                    //                                     value : true,
                    //                                     message: 'This field is required'
                    //                                 }
                    //                             })}
                    //                             render={({field,fieldState: { error }})=>(
                    //                                 <>
                    //                                     <Select {...field}
                    //                                         displayEmpty
                    //                                         inputRef={{...register('userId')}}
                    //                                         labelId="demo-multiple-checkbox-label"
                    //                                         id="userId"
                    //                                         value={users}
                    //                                         onChange={handleUserSelectChange}
                    //                                         // renderValue={(selected) => selected.join(', ')}
                    //                                         sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'11px 14px'},'& fieldset':{border : 0},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'}}}
                    //                                     >
                    //                                         <MenuItem value={''} disabled>
                    //                                             Select User
                    //                                         </MenuItem>
                    //                                         {backOfficeStuff?.data?.map( (user)=>(
                    //                                             <MenuItem value={user?.id} key={user?.id}>
                    //                                                 {/* <ListItemText className='capitalize' primary={user?.username?.toLowerCase()?.replace(/_/g," ")} /> */}
                    //                                                 <div className="[&>span]:block [&>span]:w-full">
                    //                                                     <span className="capitalize">{user?.firstName + " " + user?.lastName}</span>
                    //                                                     <span className="text-sm text-gray-400">{user?.email}</span>
                    //                                                 </div>
                    //                                             </MenuItem>
                    //                                         ) )}
                                                                    

                    //                                     </Select>                                                    
                    //                                 </>
                    //                             )}
                                                        
                    //                         />
                                                    
                    //                     </FormControl>

                    //                     {errors?.userId?.message ? <p className="capitalize text-sm text-danger mt-1">{errors?.userId?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3] mt-1">please select User</p>}
                    //                 </div>
                    //             </div>

                    //             <div className='add-user-submit-btn flex justify-end w-full mt-7 px-4 py-2'>
                    //                 <Button type='submit' disabled={users?.loading } className='disabled:opacity-50 disabled:cursor-not-allowed capitalize bg-mainColor text-white w-28'>Assign</Button>
                    //             </div>
                    //         </form>
                    //     </Box>
                    // </Modal>
                    <Modal
                        open={openModal}
                        onClose={handleCloseModal}
                        aria-labelledby="menu-item-delete-modal-title"
                        aria-describedby="menu-item-delete-modal-description"
                    >
                        <Box sx={style} dir={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}>
                            <form onSubmit={handleSubmit(submitHandler)} noValidate className='py-3 px-1'>
                                <div className="flex justify-between items-center mb-4 border-b px-4 pb-0 pt-5">
                                    <h2 className="text-[#333333] text-lg font-bold mb-5 capitalize">{t('trouble_tickets.assign_modal.title')}</h2>

                                    <div>
                                        <Button color="error" className="text-2xl -mt-2" onClick={handleCloseModal}><IoCloseCircleOutline /></Button>
                                    </div>
                                </div>

                                <div className='p-5 py-0 flex flex-wrap justify-between items-center md:gap-x-4 [&>div]:mb-3 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1'>
                                    <div className="basis-full">
                                        <label>{t('trouble_tickets.assign_modal.form.assign_to')}</label>
                                        <FormControl sx={{width: '100%' }}
                                            className="bg-[#f3f6f9] rounded-md"
                                        >
                                            <Controller
                                                control={control}
                                                name=""
                                                value={users}
                                                rules={{required:t('common.required')}}
                                                {...register('userId',{
                                                    required:{
                                                        value : true,
                                                        message: t('common.required')
                                                    }
                                                })}
                                                render={({field,fieldState: { error }})=>(
                                                    <>
                                                        <Select {...field}
                                                            displayEmpty
                                                            inputRef={{...register('userId')}}
                                                            labelId="demo-multiple-checkbox-label"
                                                            id="userId"
                                                            value={users}
                                                            onChange={handleUserSelectChange}
                                                            // renderValue={(selected) => selected.join(', ')}
                                                            sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'11px 14px'},'& fieldset':{border : 0},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'}}}
                                                        >
                                                            <MenuItem value={''} disabled>
                                                                {t('trouble_tickets.assign_modal.form.menu_helper')}
                                                            </MenuItem>
                                                            {backOfficeStuff?.data?.map( (user)=>(
                                                                <MenuItem value={user?.id} key={user?.id}>
                                                                    {/* <ListItemText className='capitalize' primary={user?.username?.toLowerCase()?.replace(/_/g," ")} /> */}
                                                                    <div className="[&>span]:block [&>span]:w-full">
                                                                        <span className="capitalize">{user?.firstName + " " + user?.lastName}</span>
                                                                        <span className="text-sm text-gray-400">{user?.email}</span>
                                                                    </div>
                                                                </MenuItem>
                                                            ) )}
                                                            

                                                        </Select>                                                    
                                                    </>
                                                )}
                                                
                                            />
                                            
                                        </FormControl>

                                        {errors?.branch_id?.message ? <p className="capitalize text-sm text-danger mt-1">{errors?.branch_id?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3] mt-1">{t('trouble_tickets.assign_modal.form.menu_helper')}</p>}
                                    </div>
                                </div>

                                <div className='add-user-submit-btn flex justify-end w-full mt-7 px-4 py-2'>
                                    <Button type='submit' disabled={users?.loading } className='disabled:opacity-50 disabled:cursor-not-allowed capitalize bg-mainColor text-white w-28'>{t('trouble_tickets.assign_modal.form.assign_btn')}</Button>
                                </div>
                            </form>
                        </Box>
                    </Modal>
                    :
                    auth?.role === 'BACK_OFFICE' ? 

                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={resolveStyle} dir={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}>
                            <div className="flex justify-between items-center border-b pb-2 mb-3">
                                <h1 className="text-xl text-[#333] capitalize font-semibold">
                                    {type === 'resolve' ? t('trouble_tickets.assign_modal.form.ticket_resolution') : type === 'close' ? t('trouble_tickets.assign_modal.form.close_ticket') : type === 're-open' ? t('trouble_tickets.assign_modal.form.close_ticket') : ''}
                                </h1>

                                {/* <div>
                                    <FaTimesCircle onClick={handleClearanceClose} className="text-mainRed text-xl cursor-pointer" />
                                </div> */}
                            </div>

                            <form noValidate onSubmit={handleSubmit(submitResolveHandler)}>
                                <div className=" [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>textarea]:bg-[#f3f6f9] [&>div>textarea]:w-full [&>div>textarea]:p-3 [&>div>textarea]:focus:outline-slate-400 [&>div>textarea]:rounded-md [&>div>textarea]:text-mainColor [&>div>textarea]:mb-1 [&>div>textarea]:resize-none">
                                            
                                    <div>
                                        <label> {type === 'resolve' ? t('trouble_tickets.assign_modal.form.summary') : type === 'close' ? t('trouble_tickets.assign_modal.form.reason') : type === 're-open' ? t('trouble_tickets.assign_modal.form.reason') : '' } </label>
                                        <textarea
                                            id={`${type === 'resolve' ? 'resolution' : type === 'close' ? 'reason' : type === 're-open' ? 'reason' : ''}`}
                                            {...register(`${type === 'resolve' ? 'resolution' : type === 'close' ? 'reason' : type === 're-open' ? 'reason' : ''}`,{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                }
                                            })}
                                        />
                                                
                                        { 
                                            type === 'resolve' ? 
                                                <p className="capitalize text-sm text-danger">{errors?.resolution?.message}</p>
                                            :
                                            type === 'close' ? 
                                                <p className="capitalize text-sm text-danger">{errors?.reason?.message}</p>
                                            :
                                            type === 're-open' ? 
                                                <p className="capitalize text-sm text-danger">{errors?.reason?.message}</p>
                                            : null
                                        }
                                    </div>
                                            
                                    <div className="flex justify-end item-center gap-x-4 mt-6 mb-0 capitalize text-sm [&>button]:text-white [&>button]:rounded-lg [&>button]:px-4">
                                        <Button className="bg-gray-400" onClick={handleClose}>{t('common.cancel_btn')}</Button>
                                        <Button className="bg-mainColor disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed" type="submit" disabled={''}>{t('common.submit')}</Button>
                                    </div>
                                </div>
                            </form>
                        </Box>
                    </Modal>
                    : null
                }
            </>
        </article>
    );
}

export default TicketDetails;