import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from "react";
import TakeinOffers from "./DP-Components/takeinOffers";
import SubscripedOffers from "./DP-Components/subscripedOffers";
import { useSelector } from "react-redux";

const PartnersDeliveryPackages = () => {
    const {t} = useTranslation()

    const [value, setValue] = useState(0);

    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };

    const packages = useSelector(state=>state?.packages)

    const tabList = [
        {id:1,title:t('dp.takein.offers'), component:<TakeinOffers t={t} packages={packages} /> },
        {id:2,title:t('dp.subscribed.offers'), component : <SubscripedOffers t={t} packages={packages} />},
    ]

    return ( 
        <article className="delivery-packages-wrapper">
            <section className="order-title">
                <h1 className="text-[#333] font-bold text-3xl mb-4 font-playfair capitalize">{t('dp.title')}</h1>
            </section>

            <section className="dp-tabs-wrapper">
                <TabContext value={value}>
                    <Box 
                        sx={{ '.MuiTabs-indicator': { backgroundColor: '#442b7e' },'.Mui-selected' : {color:"#555 !important"}}}
                    >
                        <TabList onChange={handleChange} variant="standard" sx={{'& .MuiTabs-indicator' : {backgroundColor : '#442b7e'}}}>
                            {tabList?.map( (item,index)=>(
                                    <Tab key={index} label={item?.title} value={index} className="capitalize text-[#999999] font-bold text-lg font-Ubuntu" />
                            ) )}
                        </TabList>
                    </Box>

                    <div>
                        {tabList?.map( (item,index)=>(
                            <TabPanel value={index} key={item?.id} sx={{px:0}}>
                                {item?.component}
                            </TabPanel>
                        ) )}
                    </div>
                </TabContext>
            </section>
        </article>
     );
}

export default PartnersDeliveryPackages;