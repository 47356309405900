import { Button, FormControl, IconButton, InputAdornment, OutlinedInput, Pagination, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import SubscribeModal from "./subscripeModal";
import Overlay from '../../../Common/overlay'
import { useDispatch } from "react-redux";
import { getPackages } from "../../../../Components/Redux/Slice/Packages/packages";
import NoDataEmoji from "../../../Common/nodataEmoje";
import { CiSearch } from "react-icons/ci";

const TakeinOffers = ({t,packages}) => {
    const [searchValue, setsearchValue] = useState('')

    const [id, setid] = useState(null)
    const [openModal, setopenModal] = useState(false)
    const handleOpenModal = ()=>setopenModal(true)
    const handleCloseModal = ()=>setopenModal(false)
    const dispatch = useDispatch()

    const [page, setpage] = useState(1)
    const [size, setsize] = useState(12)

    useEffect(() => {
        dispatch(getPackages({page,size}))
    }, [dispatch,page,size])

    const handlePageChange = (e,page) => {
        setpage(page);
    };

     // search functions
    const handleSearch = ()=>{
        dispatch(getPackages({searchValue}))
    }

    const handleReset = ()=>{
        dispatch(getPackages({page,size}))
        setsearchValue('')
    }

    useEffect(() => {
        dispatch(getPackages({searchValue}))
    }, [searchValue,dispatch])

    return ( 
        <>
            <article className="dp-takein-offers-wrapper min-h-[450px] h-[63vh] relative">

                <section className="search-wrapper">
                    <div>
                        <FormControl
                            className="search-form [&>div>input]:py-[.6rem]"
                            sx={{ m: 1, width: '35ch',backgroundColor:'white',borderRadius:'6px','& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input':{padding : '10px'}, '& fieldset': { borderColor: 'primary.main',border:'none' } }} variant="outlined">
                            <OutlinedInput
                                type="search"
                                id="outlined-adornment-search"
                                value={searchValue}
                                placeholder={`${localStorage?.getItem('lang') === 'ar' ? 'ابحث في الباقات' : 'Search Packages...'}`}
                                onChange={(e)=>{
                                    setsearchValue(e.target.value)
                                if (!e.target.value) {
                                    handleReset();
                                }}}
                                startAdornment={
                                <InputAdornment position="start">
                                    
                                    <IconButton
                                        aria-label="search"
                                        onClick={handleSearch}
                                        edge="start"
                                    >
                                        <CiSearch />
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                </section>
                
                <section className="dp-offers-card-wrapper grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 max-h-[520px] overflow-hidden overflow-y-auto">
                    {(packages?.packages?.data && packages?.packages?.data?.length !== 0) ? packages?.packages?.data?.map( (card,index)=>(
                            <div key={card?.id} className="bg-white rounded-lg p-3 [&>p]:mb-3 [&>p]:font-semibold">
                                <Tooltip
                                    title={localStorage.getItem('lang') === 'ar' ? card?.packageNameAr : card?.packageName}
                                >
                                    <p className="text-lg text-[#333] capitalize line-clamp-1">{localStorage.getItem('lang') === 'ar' ? card?.packageNameAr : card?.packageName}</p>
                                </Tooltip>
                                <p className="text-mainColor">{card?.discountValue} {card?.discountType === 'fixed' ? t('common.sar') : '%'}</p>
                                <Button  onClick={()=>{
                                    handleOpenModal()
                                    setid(card?.id)
                                }} disabled={card?.isSubscribed} className="disabled:opacity-30 disabled:cursor-not-allowed disabled:pointer-events-auto w-full capitalize text-center text-white bg-mainGreen">{card?.isSubscribed ? t('dp.subscriped') : t('dp.subscripe')}</Button>
                            </div>
                        ) )

                        :
                        <div className="flex items-center justify-center col-span-full h-full">
                            <NoDataEmoji />
                        </div>
                    }
                </section>
                
                    <SubscribeModal open={openModal} close={handleCloseModal} t={t} id={id} />

                    {packages?.loading ? <Overlay /> : null}
            </article>

            <section className="pagination-wrapper mt-9">
                <Pagination dir='ltr' count={packages?.packages?.meta?.totalPages} defaultPage={1} onChange={handlePageChange} />
            </section>
        </>
    );
}

export default TakeinOffers;